import { Alert, AlertDescription, AlertIcon, FormControl, HStack, Select, Text } from '@chakra-ui/react';
import React from 'react';

interface InputSelectComponent {
  error?: string;
  label: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  options: Array<{label: string, value: string}>;
  labelWidth?: number;
  showLabel?: boolean;
}

export function InputSelect(
  {
    error,
    placeholder,
    onChange,
    value,
    label,
    options,
    labelWidth = 200,
    showLabel = true,
  }: InputSelectComponent) {
  return (
    <HStack alignItems={'center'}>
      {showLabel ? <Text as={'b'} textAlign={'right'} w={labelWidth}>{label}</Text> : null}
      <FormControl isInvalid={!!error} maxW={300}>
        <Select
          maxW={300}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          value={value}
        >
          {options.map(s =>
            <option value={s.value}>{s.label}</option>,
          )}
        </Select>
      </FormControl>
      {!!error && <Alert status='error' maxW={250}>
        <AlertIcon />
        <AlertDescription fontSize={'sm'}>{error}</AlertDescription>
      </Alert>}
    </HStack>
  );


}
