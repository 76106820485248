import { AuthDtoWesendapi, AuthDtoWesendapiResponse } from './auth.dto.wesendapi';
import { wesendRequests } from '../wesend.api';

export const login = async (data: AuthDtoWesendapi): Promise<AuthDtoWesendapiResponse> => {
  try {
    const path = 'auth/login';
    const result = await wesendRequests.post(path, data);
    return result;
  } catch (e) {
    throw e;
  }
};

export const testToken = async (): Promise<boolean> => {
  try {
    const path = 'auth/testToken';
    await wesendRequests.get(path, {});
    return true;
  } catch (e) {
    throw e;
  }
};

