import {
  Box,
  Button,
  CircularProgress,
  Flex,
  Link,
  Table,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Card from 'components/card/Card';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { statusColor } from '../../../models/lead.model';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { HSeparator } from '../../../components/separator/Separator';
import { PaginatedMetaData } from '../../../services/api/lead/lead.dto.wesendapi';
import { Channel, ChannelStatus } from '../../../models/channel.model';
import { formatPhoneNumber } from '../../../helpers/firebase.helpers';

const columnHelper = createColumnHelper<Channel>();

// const columns = columnsDataCheck;
export default function ChannelsTable(props: {
  tableData: Array<Channel>,
  onSelect?: (channel: Channel) => void,
  onPageChange: (page: number) => void,
  pagination: PaginatedMetaData,
  loading: boolean,
}) {

  const {tableData, onSelect, onPageChange, pagination, loading} = props;
  const page = pagination?.currentPage || 1;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  let defaultData = tableData || [];
  const itemsPerPage = pagination?.perPage;
  const pages = Math.ceil(pagination?.total / itemsPerPage);

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
          NOME
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Link onClick={() => onSelect(info.row.original as Channel)}>
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue()}
            </Text>
          </Link>
        </Flex>
      ),
    }),
    columnHelper.accessor('phone', {
      id: 'phone',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
          TELEFONE
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Link onClick={() => onSelect(info.row.original as Channel)}>
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {formatPhoneNumber(info.getValue())}
            </Text>
          </Link>
        </Flex>
      ),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
          Status
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Tag
            size={'sm'}
            borderRadius="full"
            variant="solid"
            colorScheme={statusColor[info.getValue() as ChannelStatus]}
          >
            <TagLabel>{ChannelStatus[info.getValue() as ChannelStatus]}</TagLabel>
          </Tag>
        </Flex>
      ),
    }),
    columnHelper.accessor('type', {
      id: 'type',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
          Tipo
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
  ];

  const [data, setData] = useState([...defaultData]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
  });
  return (
    <Card flexDirection="column" w="100%" px="0px" overflowX={{sm: 'scroll', lg: 'hidden'}}>
      {/*	<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					Complex Table
				</Text>
				<Menu />
			</Flex>*/}
      {/*<Box>*/}
      <Table variant="striped" color="gray.500" mb="24px" size={'sm'}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    pe="10px"
                    borderColor={borderColor}
                    cursor="pointer"
                    onClick={header.column.getToggleSortingHandler()}>
                    <Flex
                      justifyContent="space-between"
                      align="center"
                      fontSize={{sm: '10px', lg: '12px'}}
                      color="gray.400">
                      {flexRender(header.column.columnDef.header, header.getContext())}{{
                      asc: '',
                      desc: '',
                    }[header.column.getIsSorted() as string] ?? null}
                    </Flex>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Td
                      key={cell.id}
                      fontSize={{sm: '14px'}}
                      minW={{sm: '150px', md: '200px', lg: 'auto'}}
                      borderColor="transparent">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <HSeparator/>
      <Flex justifyContent={'space-between'} pr={10} pl={5} mt={5}>
        <Box>
          <Text>
            {pagination?.next ? <span>{page * pagination?.perPage} a {(page + 1) * pagination?.perPage} de </span> : null}
            {pagination?.total > 0 ? <b>{pagination?.total}</b> : 'Nenhum'}
          </Text>
        </Box>
        <Box>
          {!!loading && <CircularProgress color={'gray'} size={5} isIndeterminate/>}
          {page > 1 ?
            <Button
              onClick={() => onPageChange(page - 1)}
              leftIcon={<ArrowBackIcon/>}
              colorScheme="gray"
              variant="ghost">
              Anterior
            </Button> : null}
          {page < pages && pages > 1 ?
            <Button
              onClick={() => onPageChange(page + 1)}
              rightIcon={<ArrowForwardIcon/>}
              colorScheme="gray"
              variant="ghost">
              Próxima
            </Button> : null}
        </Box>
      </Flex>
    </Card>
  );
}
