import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Center,
  CircularProgress,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import DefaultAuth from 'layouts/auth/Default';
// Assets
import illustration from 'assets/img/auth/auth.png';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { login, verifyAuth } from '../../../store/features/auth/auth.slice';
import { RootState } from '../../../store/store';
import { WesendLogo } from '../../../components/icons/Icons';

function SignIn() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { authenticated, error: authError, loading, waitingAuth } = useAppSelector((state: RootState) => state.auth);

  const { isValid, errors, handleSubmit, touched, handleChange, values } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      dispatch(login({ email: values.email, password: values.password }));
    },
    validationSchema: yup.object().shape({
      email: yup.string().required('Campo obrigatório').email('Email inválido'),
      password: yup.string().required('Campo obrigatório'),
    }),
  });

  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200');
  const googleText = useColorModeValue('navy.700', 'white');
  const googleHover = useColorModeValue(
    { bg: 'gray.200' },
    { bg: 'whiteAlpha.300' },
  );
  const googleActive = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.200' },
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => {
    setShow(!show);
  };

  useEffect(() => {
    dispatch(verifyAuth());
  }, []);

  useEffect(() => {
    if (authenticated) {
      navigate('/admin/indicators');
    }
  }, [authenticated]);

  if (waitingAuth) {
    return <Box w={'100%'} p={'50px'}>
      <Center>
        <CircularProgress size={'100px'} isIndeterminate />
      </Center>
    </Box>;
  }

  return (
    <DefaultAuth illustrationBackground={<WesendLogo w={300} h={200} color={'white'} />}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w='100%'
        mx={{ base: 'auto', lg: '0px' }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Entrar
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Insira seu email e senha para entrar!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: '100%', md: '420px' }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: 'auto', lg: 'unset' }}
          me='auto'
          mb={{ base: '20px', md: 'auto' }}>
          <form onSubmit={handleSubmit}>
            <FormControl isInvalid={!!errors.email && touched.email}>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                // isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='email@exemplo.com'
                fontWeight='500'
                size='lg'
                name='email'
                value={values.email}
                onChange={handleChange('email')}
              />
              {!!errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={!!errors.password && touched.password} mt={5}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Senha<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  fontSize='sm'
                  placeholder='Min. 8 characters'
                  mb='24px'
                  size='lg'
                  onChange={handleChange('password')}
                  value={values.password}
                  type={show ? 'text' : 'password'}
                  variant='auth'
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              {!!errors.password && <FormErrorMessage>{errors.password}</FormErrorMessage>}
              {authError && <Alert mb={5} status='error'>
                <AlertIcon />
                {/*<AlertTitle>Usuário ou senha inválido</AlertTitle>*/}
                <AlertDescription>Usuário ou senha inválido.</AlertDescription>
              </Alert>}
              <Button
                isLoading={loading}
                type='submit'
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'>
                Entrar
              </Button>
            </FormControl>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
