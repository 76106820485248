import {theme, useColorModeValue} from "@chakra-ui/react";

export default {
  flex: 1,
  margin: '15px 15px 15px 0',
  control: {
  },
  '&multiLine': {
    control: {
      // fontFamily: 'monospace',
      // minHeight: 63,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
      fontWeight: 'bold',

    },
    input: {
      padding: 9,
      border: '1px solid silver',
    },
  },
  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 16,
    },
    item: {
      borderRadius: '10px',
      padding: '5px 15px',
      fontWeight: 'bold',
      color: 'gray',
      '&focused': {
        color: 'white',
        backgroundColor: theme.colors.blue["500"]
      },
    },
  },
}
