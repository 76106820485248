import * as React from 'react';
import {
  LeadMessageStatus,
  LeadProposalStatus,
  LeadSimulationStatus,
  messageStatusName,
  proposalStatusName,
  simulationStatusName,
  statusColor,
} from '../../models/lead.model';
import Filter from '../filter';
import { AddedFilterType, FilterItem } from '../filter/filter.constants';
import { LeadFilterInput } from '../../services/api/lead/lead.dto.wesendapi';
import { SimulationType, SimulationTypeName } from '../../models/simulation.model';
import { BankType, BankTypeName } from '../../models/robot.model';
import { format, parse } from 'date-fns';

export type LeadFilterParameters =
  'name'
  | 'cpf'
  | 'phone'
  | 'creditValue'
  | 'simulationDate'
  | 'simulationType'
  | 'simulationBankType'
  | 'simulationStatus'
  | 'messageStatus'
  | 'messageDate'
  | 'proposalStatus'
  | 'proposalDate';

export type AddedFilter = { [key in Partial<LeadFilterParameters>]: FilterItem };

const options: AddedFilter =
  {
    simulationStatus: {
      key: 'simulationStatus',
      label: 'Simulação: Situação',
      type: 'options',
      options: Object.entries(LeadSimulationStatus).map(
        ([label, value]) => {
          return {
            value: value,
            label: simulationStatusName[value],
            colorScheme: statusColor[value],
          };
        },
      ),
    },
    messageStatus: {
      key: 'messageStatus',
      label: 'Mensagem: Situação',
      type: 'options',
      options: Object.entries(LeadMessageStatus).map(
        ([label, value]) => {
          return {
            value: value,
            label: messageStatusName[value],
            colorScheme: statusColor[value],
          };
        },
      ),
    },
    proposalStatus: {
      key: 'proposalStatus',
      label: 'Proposta: Situação',
      type: 'options',
      options: Object.entries(LeadProposalStatus).map(
        ([label, value]) => {
          return {
            value: value,
            label: proposalStatusName[value],
            colorScheme: statusColor[value],
          };
        },
      ),
    },
    creditValue: {
      key: 'creditValue',
      label: 'Lead: Crédito',
      type: 'rangeNumber',
    },
    simulationDate: {
      key: 'simulationDate',
      label: 'Simulação: Data',
      type: 'rangeDate',
    },
    cpf: {
      key: 'cpf',
      label: 'Lead: CPF',
      type: 'text',
    },
    name: {
      key: 'name',
      label: 'Lead: Nome',
      type: 'text',
    },
    phone: {
      key: 'phone',
      label: 'Lead: Telefone',
      type: 'text',
    },
    simulationType: {
      key: 'simulationType',
      label: 'Simulação: Tipo',
      type: 'options',
      options: Object.entries(SimulationType).map(
        ([label, value]) => {
          return {
            value: value,
            label: SimulationTypeName[value],
            colorScheme: 'gray',
          };
        },
      ),
    },
    simulationBankType: {
      key: 'simulationBankType',
      label: 'Simulação: Banco',
      type: 'options',
      options: Object.entries(BankType).map(
        ([label, value]) => {
          return {
            value: value,
            label: BankTypeName[value],
            colorScheme: 'gray',
          };
        },
      ),
    },
    messageDate: {
      key: 'messageDate',
      label: 'Mensagem: Data',
      type: 'rangeDate',
    },
    proposalDate: {
      key: 'proposalDate',
      label: 'Proposta: Data',
      type: 'rangeDate',
    },
  };


export function convertLeadFilterToAddedFilter(leadFilter: LeadFilterInput): Array<AddedFilterType> {
  const addedFilter: Array<AddedFilterType> = [];
  for (const filter in leadFilter) {
    // @ts-ignore
    if (leadFilter[filter] === undefined || leadFilter[filter] === null) {
      continue;
    }
    switch (filter as keyof LeadFilterInput) {
      case 'cpf':
        addedFilter.push({
          key: 'cpf',
          text: leadFilter['cpf'],
        });
        break;
      case 'name':
        addedFilter.push({
          key: 'name',
          text: leadFilter['name'],
        });
        break;
      case 'phone':
        addedFilter.push({
          key: 'phone',
          text: leadFilter['phone'],
        });
        break;
      case 'startSimulationDate':
        console.log(filter);
        addedFilter.push({
          key: 'simulationDate',
          dateStart: parse(String(leadFilter['startSimulationDate']), 'yyyy-MM-dd', new Date()),
          dateEnd: parse(String(leadFilter['endSimulationDate']), 'yyyy-MM-dd', new Date()),
        });
        break;
      case 'startMessageDate':
        addedFilter.push({
          key: 'messageDate',
          dateStart: parse(String(leadFilter['startMessageDate']), 'yyyy-MM-dd', new Date()),
          dateEnd: parse(String(leadFilter['endMessageDate']), 'yyyy-MM-dd', new Date()),
        });
        break;
      case 'startProposalDate':
        addedFilter.push({
          key: 'proposalDate',
          dateStart: parse(String(leadFilter['startProposalDate']), 'yyyy-MM-dd', new Date()),
          dateEnd: parse(String(leadFilter['endProposalDate']), 'yyyy-MM-dd', new Date()),
        });
        break;
      case 'simulationStatus':
        if (leadFilter['simulationStatus'].length) {
          addedFilter.push({
            key: 'simulationStatus',
            // @ts-ignore
            values: options['simulationStatus'].options.filter(o => leadFilter['simulationStatus'].includes(o.value)),
          });
        }
        break;
      case 'proposalStatus':
        if (leadFilter['proposalStatus'].length) {
          addedFilter.push({
            key: 'proposalStatus',
            // @ts-ignore
            values: options['proposalStatus'].options.filter(o => leadFilter['proposalStatus'].includes(o.value)),
          });
        }
        break;
      case 'messageStatus':
        if (leadFilter['messageStatus'].length) {
          addedFilter.push({
            key: 'messageStatus',
            // @ts-ignore
            values: options['messageStatus'].options.filter(o => leadFilter['messageStatus'].includes(o.value)),
          });
        }
        break;
      case 'minCreditValue':
        addedFilter.push({
          key: 'creditValue',
          min: leadFilter['minCreditValue'],
          max: leadFilter['maxCreditValue'],
        });
        break;
      case 'simulationBankType':
        if (leadFilter['simulationBankType'].length) {
          addedFilter.push({
            key: 'simulationBankType',
            // @ts-ignore
            values: options['simulationBankType'].options.filter(o => leadFilter['simulationBankType'].includes(o.value)),
          });
        }
        break;
      default:
        break;
    }
  }
  return addedFilter;
}

export default function LeadFilter({ onFilter, initialFilter, loading, onClick, onExport, externalFilter }: {
  onFilter: (f: LeadFilterInput) => void,
  loading: boolean,
  externalFilter?: Array<AddedFilterType>,
  initialFilter?: Array<AddedFilterType>,
  onClick?: () => void
  onExport?: (limit?: number) => void
}) {
  function applyFilters(filters: Array<AddedFilterType>) {
    const leadFilters: LeadFilterInput = {};
    console.log('applyFilters', filters)
    for (const filter of filters) {
      switch (filter.key as LeadFilterParameters) {
        case 'cpf':
          leadFilters.cpf = filter.text;
          break;
        case 'name':
          leadFilters.name = filter.text;
          break;
        case 'phone':
          leadFilters.phone = filter.text;
          break;
        case 'simulationDate':
          leadFilters.startSimulationDate = format(filter.dateStart,'yyyy-MM-dd');
          leadFilters.endSimulationDate = format(filter.dateEnd,'yyyy-MM-dd');
          break;
        case 'messageDate':
          leadFilters.startMessageDate = format(filter.dateStart,'yyyy-MM-dd');
          leadFilters.endMessageDate = format(filter.dateEnd,'yyyy-MM-dd');
          break;
        case 'proposalDate':
          leadFilters.startProposalDate = format(filter.dateStart,'yyyy-MM-dd');
          leadFilters.endProposalDate = format(filter.dateEnd,'yyyy-MM-dd');
          break;
        case 'simulationStatus':
          leadFilters.simulationStatus = filter.values.map(v => v.value);
          break;
        case 'proposalStatus':
          leadFilters.proposalStatus = filter.values.map(v => v.value);
          break;
        case 'simulationBankType':
          leadFilters.simulationBankType = filter.values.map(v => v.value);
          break;
        case 'messageStatus':
          leadFilters.messageStatus = filter.values.map(v => v.value);
          break;
        case 'creditValue':
          leadFilters.maxCreditValue = filter.max;
          leadFilters.minCreditValue = filter.min;
          break;
        default:
          break;
      }
    }
    console.log('leadFilters', leadFilters)
    if (Object.keys(leadFilters).length) {
      onFilter(leadFilters);
    }
  }

  return (
    <Filter
      onClick={onClick}
      options={options}
      onApply={applyFilters}
      loading={loading}
      initialFilter={initialFilter}
      onExport={onExport}
      externalFilter={externalFilter}
    />
  );
}
