import { useEffect, useState } from 'react';
import { Flex, Input, Text } from '@chakra-ui/react';
import { format, parse } from 'date-fns';
import * as React from 'react';
import { InputRangeDateType } from './filter.constants';

export function FilterItemInputRangeDate(
  {
    onChange,
    value,
  }: {
    label: string,
    onChange: (v: InputRangeDateType) => void,
    value: InputRangeDateType;
  },
) {

  const [dateStart, setDateStart] = useState<Date>(new Date());
  const [dateEnd, setDateEnd] = useState<Date>(new Date());

  useEffect(() => {
    onChange({ dateStart, dateEnd });
  }, [dateStart, dateEnd]);

  console.log('value', value)
  return (
    <Flex align={'center'} gap={5}>
      <Input
        placeholder='Selecione'
        size='sm'
        type='date'
        value={format(value?.dateStart || dateStart, 'yyyy-MM-dd')}
        onChange={(event: any) => setDateStart(parse(event.target.value, 'yyyy-MM-dd', new Date()))}
      />
      <Text>
        até
      </Text>
      <Input
        min={format(value?.dateStart || dateStart, 'yyyy-MM-dd')}
        placeholder='Selecione'
        size='sm'
        type='date'
        value={format(value?.dateEnd || dateEnd, 'yyyy-MM-dd')}
        onChange={(event: any) => setDateEnd(parse(event.target.value, 'yyyy-MM-dd', new Date()))}
      />
    </Flex>
  );
}
