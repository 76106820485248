import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getLeads } from '../../store/features/leads/leads.api';
import { RootState } from '../../store/store';
import { Lead, LeadMessageStatus, LeadSimulationStatus } from '../../models/lead.model';
import LeadsTable from './components/LeadsTable';
import { ModalLead } from './components/ModalLead';
import { LeadFilterInput } from '../../services/api/lead/lead.dto.wesendapi';
import LeadFilter, { convertLeadFilterToAddedFilter } from '../../components/leadFilter';
import { FiRefreshCcw } from 'react-icons/fi';
import FabLeads from './components/FabLeads';
import { ExportButton } from './components/ModalExportLeads';
import { BankType } from '../../models/robot.model';
import ModalEditLeadsByCPF from './components/ModalEditByText';
import ImportButton from './components/import';
import EmptyState from '../../components/emptyState/emptyState.component';

type rapidFiltersTypes = 'credit' | 'phone' | 'cpf';
const rapidFilters: {[key in rapidFiltersTypes]: { filters: LeadFilterInput, label: string }} = {
  credit: {
    label: 'Créditos novos',
    filters: {
      simulationStatus: [LeadSimulationStatus.CREDIT],
      messageStatus: [LeadMessageStatus.NONE],
      simulationBankType: [BankType.C6, BankType.FACTA, BankType.PARANA],
    }
  },
  phone: {
    label: 'Telefone',
    filters: {
      phone: '',
    }
  },
  cpf: {
    label: 'CPF',
    filters: {
      cpf: '',
    }
  },
}

export default function Leads() {

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const { isOpen: isOpenModalEditLeadsByCPF, onOpen: onOpenisOpenModalEditLeadsByCPF, onClose: onCloseisOpenModalEditLeadsByCPF } = useDisclosure();
  const [selectedLead, setSelectedLead] = useState<Lead | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { leads, loading, error, pagination } = useAppSelector((state: RootState) => state.leads);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<LeadFilterInput>({});
  const [isFiltering, setIsfiltering] = useState<boolean>(false);
  const [selectedLeads, setSelectedLeads] = useState<Array<string>>([]);

  useEffect(() => {
    dispatch(getLeads({ page, filter }));
  }, [page]);

  useEffect(() => {
    if (selectedLead)
      onToggle();
  }, [selectedLead]);

  useEffect(() => {
    dispatch(getLeads({ page, filter }));
  }, [filter]);

  function closeDrawerLead(updated: boolean) {
    onClose();
    if (updated) {
      dispatch(getLeads({ page, filter }));
    }
  }

  function refresh() {
    dispatch(getLeads({ page, filter }));
  }

  function updateFilter(newFilter: LeadFilterInput) {
    setFilter(newFilter);
    if (newFilter && !Object.keys(newFilter).length)  {
      setIsfiltering(false);
    }
  }

  // async function exportCsv(limit?: number) {
  //   if (limit) {
  //     filter.limit = limit;
  //   }
  //   const response = await dispatch(exportLeads({ filter }));
  //   if (response.payload) {
  //     showToast('success', `Leads exportados!`);
  //     const a = document.createElement('a');
  //     // @ts-ignore
  //     const blob = new Blob([response.payload], { type: 'text/csv' });
  //     const url = window.URL.createObjectURL(blob);
  //     a.href = url;
  //     a.download = `[wesend] Leads exportados ${format(new Date(),'dd/MM/yyyy-HH:mm')}.csv`;
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //     a.remove();
  //   }
  // }

  function onSelectRow(selectedLeads: Array<string>) {
    setSelectedLeads(selectedLeads);
  }

  function applyRapidFilter(type: rapidFiltersTypes) {
    updateFilter(rapidFilters[type].filters);
    setIsfiltering(true);
  }

  return (
    <>
      <Box mt={{ base: '120px', md: '80px' }}>
        <Flex  mb={5} gap={5} flexDirection={{ base: 'column', md: 'row' }} alignItems={'end'}>
          {!isFiltering ? <Flex gap={5} justifyContent={'space-between'}>
            <Button ml={2} mr={5} rightIcon={<FiRefreshCcw />} colorScheme='blue' variant='link' onClick={refresh}
                    isLoading={loading}>
              Atualizar
            </Button>
            <Menu>
              <MenuButton mr={5} colorScheme='blue' variant='link' as={Button}>
                Filtros rápidos
              </MenuButton>
              <MenuList>
                {Object.keys(rapidFilters).map(filter =>
                  <MenuItem key={filter}
                    onClick={() => applyRapidFilter(filter as rapidFiltersTypes)}>{rapidFilters[filter as rapidFiltersTypes].label}</MenuItem>,
                )}
              </MenuList>
            </Menu>
            {!isFiltering ? <Button mr={3} colorScheme='blue' variant='link' onClick={onOpenisOpenModalEditLeadsByCPF}>
              Editar por CPF
            </Button>: null}
          </Flex> : null}
          <LeadFilter onFilter={updateFilter} loading={loading} onClick={() => setIsfiltering(true)}
                      externalFilter={convertLeadFilterToAddedFilter(filter)} />
          <Flex gap={5}>
            {!isFiltering ? <ImportButton></ImportButton> : null}
            <ExportButton loading={loading} initialLimit={pagination.total}
                          filter={filter} totalCount={pagination.total} selectedLeads={selectedLeads}
                          onUpdate={refresh}></ExportButton>
          </Flex>
        </Flex>
        <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
          <EmptyState text={"Nenhum lead criado"} empty={leads && !leads.length} loading={loading} filtering={isFiltering} textFilter={'Nenhum lead encontrado'}>
            <LeadsTable
              pagination={pagination}
              tableData={leads || []}
              onSelect={(l) => setSelectedLead(l)}
              onPageChange={v => setPage(v)}
              loading={loading}
              onSelectRow={onSelectRow}
            />
          </EmptyState>
        </SimpleGrid>
      </Box>
      {isOpen ? <ModalLead lead={selectedLead} isOpen={isOpen}
                           onClose={(updated: boolean) => closeDrawerLead(updated)} /> : null}
      {selectedLeads.length ?
        <FabLeads
          selectedLeads={selectedLeads}
          totalCount={pagination.total}
          filter={filter}
          onUpdate={refresh}
        />
        : null}
      <ModalEditLeadsByCPF
        isOpen={isOpenModalEditLeadsByCPF}
        onOpen={onOpenisOpenModalEditLeadsByCPF}
        onClose={onCloseisOpenModalEditLeadsByCPF}
        selectedLeads={selectedLeads}
        onUpdate={refresh}
      />
    </>
  );
}
