import React from 'react';
import './assets/css/App.css';
import {HashRouter, Route, Routes} from 'react-router-dom';
import AdminLayout from './layouts/admin';
import {ChakraProvider} from '@chakra-ui/react';
import theme from './theme/theme';
import {store} from './store/store';
import {Provider} from 'react-redux';
import SignInCentered from './views/auth/signIn';

import {createRoot} from 'react-dom/client';
import { SubscriptionAlert } from './services/subscription/subscription.alert';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <HashRouter>
            <Routes>
              <Route index element={<AdminLayout/>}/>
              <Route path='auth' element={<SignInCentered/>}/>
              <Route path='admin' element={<AdminLayout/>}/>
              <Route path="*" element={<AdminLayout/>}/>
              {/*<PrivateRoute path={`/rtl`} component={RTLLayout}/>*/}
              {/*<Navigate to="admin" replace/>*/}
              {/*<Redirect from='/' to='/admin'/>*/}
            </Routes>
        </HashRouter>
        <SubscriptionAlert/>
      </React.StrictMode>
    </ChakraProvider>
  </Provider>
);
