// Chakra imports
import { Flex, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { WesendLogo } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
	//   Chakra color mode
	let logoColor = useColorModeValue('brand.500', 'white');

	return (
		<Flex alignItems='center' flexDirection='column'>
			<WesendLogo h='30px' w='250px' color={logoColor} ml={'-20px'} />
			<HSeparator mb='20px' mt='20px' />
		</Flex>
	);
}

export default SidebarBrand;
