import {
  Box,
  Button, CircularProgress,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack, Image,
  SimpleGrid,
  Spacer,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/store';
import { Channel, ChannelStatus, ChannelType } from '../../../models/channel.model';
import { BiTrashAlt } from 'react-icons/bi';
import { showToast } from '../../../services/toast.service';
import * as yup from 'yup';
import { InputText } from '../../../components/inputText/inputText.component';
import { channelStatusName, statusColor } from '../../../models/lead.model';
import {
  createChannel,
  editChannel,
  getChannel,
  removeChannel,
  restartChannel,
} from '../../../store/features/channel/channel.slice';
import { FiRefreshCcw } from 'react-icons/fi';
import { formatPhoneNumber } from '../../../helpers/firebase.helpers';
import ConfirmationButton from '../../../components/buttons/RemoveConfirmation';

interface IModalChannel {
  channel: Channel | undefined;
  isOpen: boolean;
  onClose: (updated: boolean) => void;
}

export function ModalChannel({ channel: selectedChannel, isOpen, onClose }: IModalChannel) {
  const { loadingUpdate, error } = useAppSelector((state: RootState) => state.channel);
  const [channel, setChannel] = useState<Channel>(selectedChannel || null);
  const [status, setStatus] = useState<ChannelStatus>(channel?.id ? channel.status : ChannelStatus.OFFLINE);
  const [qr, setQr] = useState<string>(channel?.id ? channel.qr : null);
  const dispatch = useAppDispatch();
  const { handleSubmit, handleChange, values, resetForm, errors, setFieldValue } = useFormik({
    initialValues: {
      id: channel?.id,
      name: channel?.name,
      phone: channel?.phone,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Campo obrigatório"),
    }),
    onSubmit: async (values) => {
      if (!channel?.id) {
        const response = await dispatch(createChannel({ ...values, type: ChannelType.WHATSAPP } as Channel));
        if (response.payload) {
          showToast('success', 'Canal Criado!');
          setChannel(response.payload as Channel)
        }
      } else {
        const response = await dispatch(editChannel({ ...values, type: ChannelType.WHATSAPP } as Channel));
        if (response.payload) {
          showToast('success', 'Canal Editado!');
          resetForm();
          onClose(true);
        }
      }
    },
  });

  useEffect(() => {
    if (!channel?.id) {
      return;
    }
    console.log('channel', channel);
    const intervalId = setInterval(async () => {
      const channelUpdatedResponse = await dispatch(getChannel(channel.id));
      const channelUpdated = channelUpdatedResponse.payload as Channel;
      setFieldValue('id', channelUpdated.id);
      setStatus(channelUpdated.status);
      setQr(channelUpdated.qr);
      if (channelUpdated.phone) {
        setFieldValue('phone', channelUpdated.phone);
      }
    }, 2000);
    return () => clearInterval(intervalId);
  }, [channel]);

  useEffect(() => {
    if (error) {
      showToast('error', error);
    }
  }, [error]);

  async function remove() {
    const response = await dispatch(removeChannel({
      id: channel.id,
    }));
    if (response.payload) {
      showToast('success', 'Robô removido!');
      onClose(true);
    } else {
      showToast('error', error);
    }
  }

  async function restart() {
    const response = await dispatch(restartChannel({
      id: channel.id,
    }));
    if (response.payload) {
      showToast('success', `Reiniciado!`);
    } else {
      showToast('error', error);
    }
  }

  function closeModal() {
    resetForm();
    onClose(true);
  }

  return (
    <Drawer
      size={'xl'}
      isOpen={isOpen}
      placement='right'
      onClose={closeModal}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          {channel?.name || 'Novo canal'}
          <Tag
            ml={5}
            borderRadius="full"
            variant="solid"
            size={'lg'}
            colorScheme={statusColor[status]}
          >
            <TagLabel>{channelStatusName[status]}</TagLabel>
          </Tag>
        </DrawerHeader>
        <DrawerBody>
          <Box borderWidth='1px' borderRadius='lg' p={5} overflow='hidden' mb={5}>
            <SimpleGrid columns={1} spacing={{ base: '20px', xl: '20px' }}>
              <InputText
                label='Nome'
                value={values?.name}
                onChange={handleChange('name')}
                error={errors.name}
              />
              {channel?.id ? <InputText
                label='Telefone'
                value={formatPhoneNumber(values?.phone)}
                disabled={true}
                onChange={handleChange('phone')}
                error={errors.phone}
              /> : null}
              {channel?.id && status === ChannelStatus.OFFLINE ? <>
              {!qr ? <CircularProgress m={100} isIndeterminate={true} size={100}/> : null}
              {qr ? <Image mt={50} src={qr} alt='QR' /> : null}
              </> : null}
            </SimpleGrid>
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <Flex w={'100%'} gap={2}>
            {channel?.id ?  <Button rightIcon={<FiRefreshCcw />} colorScheme='yellow' onClick={() => restart()}
                    size={'md'}
                    isLoading={loadingUpdate}>
              Reiniciar
            </Button> : null}
            {channel?.id ? <ConfirmationButton onConfirm={remove}>
              <Button rightIcon={<BiTrashAlt />} colorScheme='red' size={'md'} isLoading={loadingUpdate}>
                Remover
              </Button>
            </ConfirmationButton> : null}
            <Spacer />
            <HStack>
              <Button colorScheme='gray' mr={3} onClick={closeModal}>
                Fechar
              </Button>
              <Spacer />
              <Button isLoading={loadingUpdate} colorScheme='blue' mr={3} onClick={() => handleSubmit()}>
                Salvar
              </Button>
            </HStack>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
