import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CampaignFilterInput, Campaing, CreateCampaingDto } from '../../../models/campaing.model';
import { CreateLeadDto, LeadMessageStatus, LeadProposalStatus, LeadSimulationStatus } from '../../../models/lead.model';
import { wesendApi } from '../../../services/api/wesend.api';
import { PaginatedMetaData } from '../../../services/api/lead/lead.dto.wesendapi';
import { initImportation } from '../leads/leads.api';
import { getCompanySubscription } from '../company/company.slice';

export interface CampaingFilters {
  phone?: string;
  cpf?: string;
  messageStatus?: LeadMessageStatus;
  proposalStatus?: LeadProposalStatus;
  simulationStatus?: LeadSimulationStatus;
  limit: number;
  lastUpdateStart?: string,
  lastUpdateEnd?: string,
}

export interface CampaingState {
  loading: boolean;
  error: string | null;
  count: number,
  filters: CampaingFilters | undefined;
  campaings: Array<Campaing>;
  pagination: PaginatedMetaData | undefined;
  loadingUpdate: boolean;
}

const initialState: CampaingState = {
  loading: false,
  error: null,
  count: 0,
  filters: undefined,
  campaings: [],
  loadingUpdate: false,
  pagination: {
    total: 0,
    lastPage: 0,
    currentPage: 0,
    perPage: 20,
    prev: 0,
    next: 0,
  },
};

export const createCampaing = createAsyncThunk<string, CreateCampaingDto>(
  'campaign/create',
  async (data, { getState, dispatch }) => {
    const result = await wesendApi.campaign.create(data);
    dispatch(initImportation());
    dispatch(getCompanySubscription());
    return result;
  });

export const stopCampaign = createAsyncThunk<Campaing, string>(
  'campaign/stop',
  async (id, { getState, dispatch }) => {
    return await wesendApi.campaign.stop(id);
  });

export const getCampaings = createAsyncThunk<{ campaings: Array<Campaing>, pagination: PaginatedMetaData },{ page: number, filter: CampaignFilterInput}>(
  'campaign/list',
  async (params, {dispatch, getState}) => {
    const campaings = await wesendApi.campaign.list({
      ...params.filter,
      page: params.page,
    });
    return { campaings: campaings.data, pagination: campaings.meta };
  },
);

export const createBatchCampaing = createAsyncThunk<string, { id: string, leads: Array<CreateLeadDto>}>(
  'campaign/createBatchCampaing',
  async (_, {dispatch, getState}) => {
    return await wesendApi.typingRobot.createBatchTypingRobot(_.id, _.leads);
  },
);

export const exportCampaingBatch = createAsyncThunk<string, string>(
  'campaign/exportBatch',
  async (id, {dispatch, getState}) => {
    const csv = await wesendApi.campaign.exportCampaingBatch(id);
    return csv;
  },
);

export const campaingSlice = createSlice({
  name: 'Channel',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(stopCampaign.pending, (state) => {
        state.loadingUpdate = true;
        state.error = undefined;
      })
      .addCase(stopCampaign.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(stopCampaign.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(createCampaing.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(createCampaing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createCampaing.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(getCampaings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCampaings.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.campaings = action.payload.campaings;
        state.pagination = action.payload.pagination;
      })
      .addCase(getCampaings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createBatchCampaing.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBatchCampaing.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(createBatchCampaing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(exportCampaingBatch.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportCampaingBatch.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(exportCampaingBatch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export const {updateFilters} = campaingSlice.actions;

