import { Alert, AlertDescription, AlertIcon, Flex, FormControl, HStack, Switch, Text } from '@chakra-ui/react';
import React from 'react';
import { InputNumber } from '../inputNumber/inputNumber.component';

interface IInputCheckNumberValue {
  enabled: boolean;
  number: number;
}

interface IInputCheckNumber {
  error?: string;
  onChange: (value: IInputCheckNumberValue) => void;
  label?: string;
  disabled?: boolean;
  witdh?: number;
  value: IInputCheckNumberValue;
}

export function InputCheckNumber(
  {
    error,
    onChange,
    value,
    label,
    disabled,
    witdh,
  }: IInputCheckNumber) {
  return (
    <HStack alignItems={'center'}>
      {label ? <Text as={'b'} textAlign={'right'} w={200}>{label}</Text> : null}
      <FormControl isInvalid={!!error} maxW={100}>
        <Switch
          size='lg'
          isChecked={value.enabled}
          isDisabled={!!disabled}
          onChange={(e) => {
            onChange({
              enabled: e.target.checked,
              number: value.number,
            });
          }}
        />
      </FormControl>
      {value.enabled ?
      <Flex align={'center'} gap={5}>
        <InputNumber
          value={value.number}
          onChange={(number) =>
            onChange({
              enabled: value.enabled,
              number: number,
            })}
          min={100}
          max={100000}
        />
      </Flex> : null}
      {!!error && <Alert status='error' maxW={250}>
        <AlertIcon />
        <AlertDescription fontSize={'sm'}>{error}</AlertDescription>
      </Alert>}
    </HStack>
  );
}
