import { Box, Center, Container, Flex, Link, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { CSSProperties, useState } from 'react';
import { formatFileSize, useCSVReader } from 'react-papaparse';

const styles = {
  zone: {
    alignItems: 'center',
    border: `2px dashed grey`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    height: 200,
    justifyContent: 'center',
    padding: 20,
  } as CSSProperties,
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  } as CSSProperties,
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  size: {
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  } as CSSProperties,
  name: {
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  } as CSSProperties,
  progressBar: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  zoneHover: {
    borderColor: 'grey',
  } as CSSProperties,
  default: {
    borderColor: 'grey',
  } as CSSProperties,
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  } as CSSProperties,
};

const Asterisk = () => <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>;

export default function Upload(props: {
  used?: number;
  total?: number;
  [x: string]: any,
  onFiles?: (files: string) => void,
  onData?: (files: Array<any>) => void
}) {
  const { used, total, onFiles, onData, ...rest } = props;
  const { CSVReader } = useCSVReader();

  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    'red',
  );

  return (
    <Card {...rest} mb='20px' alignItems='center' p='20px'>
      <Flex flexDirection={{ base: 'column', md: 'row'}}>
        <Box>
          <Text>
            O arquivo deve estar no formato CSV e deve conter 3 colunas, na seguinte ordem: <b>CPF<Asterisk />, NOME e TELEFONE</b>.
          </Text>
          <Text mt={5} maxW={{ base: '100%', md: '50%'}} color={'red.700'}>
            CPF obrigatório!
          </Text>
        </Box>
        <CSVReader
          h={'300px'}
          onUploadAccepted={(results: any) => {
            setZoneHover(false);
            onData(results.data);
          }}
          onDragOver={(event: DragEvent) => {
            event.preventDefault();
            setZoneHover(true);
          }}
          onDragLeave={(event: DragEvent) => {
            event.preventDefault();
            setZoneHover(false);
          }}
        >
          {({
              getRootProps,
              acceptedFile,
              ProgressBar,
              getRemoveFileProps,
              Remove,
            }: any) => {
            if (acceptedFile) {
              onFiles(acceptedFile.name);
            }
            return <Center>
              <Box
                maxW={{ base: '100%', md: '50%'}}
                {...getRootProps()}
                style={Object.assign(
                  {},
                  styles.zone,
                  zoneHover && styles.zoneHover,
                )}
              >
                {acceptedFile ? (
                  <Container>
                    <div style={styles.info}>
                    <span style={styles.size}>
                      {formatFileSize(acceptedFile.size)}
                    </span>
                      <span style={styles.name}>{acceptedFile.name}</span>
                      <div style={styles.progressBar}>
                        <ProgressBar />
                      </div>
                    </div>
                    <div
                      {...getRemoveFileProps()}
                      style={styles.remove}
                      onMouseOver={(event: Event) => {
                        event.preventDefault();
                        setRemoveHoverColor('red');
                      }}
                      onMouseOut={(event: Event) => {
                        event.preventDefault();
                        setRemoveHoverColor('red');
                      }}
                    >
                      <Remove color={removeHoverColor} />
                    </div>
                  </Container>
                ) : (
                  'Arraste o arquivo CSV aqui ou clique para selecionar'
                )}
              </Box>
            </Center>;
          }}
        </CSVReader>
      </Flex>
    </Card>
  )
    ;
}
