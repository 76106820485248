import { Alert, AlertDescription, AlertIcon, FormControl, HStack, Input, Text } from '@chakra-ui/react';
import React from 'react';

interface InputTextComponent {
  error?: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  label: string;
  disabled?: boolean;
}

export function InputText(
  {
    error,
    placeholder,
    onChange,
    value,
    label,
    disabled,
  }: InputTextComponent) {
  return (
    <HStack alignItems={'center'}>
      <Text as={'b'} textAlign={'right'} w={200}>{label}</Text>
      <FormControl isInvalid={!!error} maxW={300}>
        <Input placeholder={placeholder || label}
               onChange={(e) => onChange(e.target.value)}
               disabled={disabled}
               value={value}>
        </Input>
      </FormControl>
      {!!error && <Alert status='error' maxW={250}>
        <AlertIcon />
        <AlertDescription fontSize={'sm'}>{error}</AlertDescription>
      </Alert>}
    </HStack>
  );


}
