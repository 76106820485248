import { wesendRequests } from '../wesend.api';
import { RobotFilterInput, RobotStatusType } from '../../../models/robot.model';
import { PaginatedResult } from '../lead/lead.dto.wesendapi';
import { TypingRobot } from '../../../models/typingRobot.model';
import { CreateLeadDto } from '../../../models/lead.model';

export const getTypingRobots = async (data: RobotFilterInput & {
  // orderBy?: UserOrderByInput,
  page?: number,
}): Promise<PaginatedResult<TypingRobot>> => {
  try {
    const path = 'typingRobot';
    const result = await wesendRequests.get(path, data);
    return result;
  } catch (e) {
    throw e;
  }
};

export const removeTypingRobot = async (id: string): Promise<boolean> => {
  try {
    const path = `typingRobot/${id}`;
    const result = await wesendRequests.delete(path);
    return result;
  } catch (e) {
    throw e;
  }
};

export const changeTypingRobotStatus = async (id: string, status: RobotStatusType): Promise<boolean> => {
  try {
    const path = `typingRobot/changeStatus/${id}`;
    const result = await wesendRequests.post(path, { status });
    return result;
  } catch (e) {
    throw e;
  }
};

export const createTypingRobot = async (typingRobot: TypingRobot): Promise<TypingRobot> => {
  try {
    const path = `typingRobot`;
    const result = await wesendRequests.post(path, typingRobot);
    return result;
  } catch (e) {
    console.log('createTypingRobot', e)
    throw e;
  }
};

export const editTypingRobot = async (typingRobot: TypingRobot): Promise<TypingRobot> => {
  try {
    const path = `typingRobot/${typingRobot.id}`;
    const result = await wesendRequests.put(path, typingRobot);
    return result;
  } catch (e) {
    console.log('createTypingRobot', e)
    throw e;
  }
};

export const createBatchTypingRobot = async (typingRobotId: string, leads: Array<CreateLeadDto>): Promise<string> => {
  try {
    const path = `typingRobot/importLeads/${typingRobotId}`;
    const result = await wesendRequests.post(path, leads);
    return result;
  } catch (e) {
    console.log('createTypingRobot', e)
    throw e;
  }
};

export const exportTypingBatch = async (typingRobotId: string): Promise<string> => {
  try {
    const path = `typingRobot/exportBatch/${typingRobotId}`;
    const result = await wesendRequests.post(path, {});
    return result;
  } catch (e) {
    console.log('createTypingRobot', e)
    throw e;
  }
};
