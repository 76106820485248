import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateVoiceCampaignDto, VoiceCampaign, VoiceCampaignFilterInput } from '../../../models/voiceCampaing.model';
import { CreateLeadDto, LeadMessageStatus, LeadProposalStatus, LeadSimulationStatus } from '../../../models/lead.model';
import { wesendApi } from '../../../services/api/wesend.api';
import { PaginatedMetaData } from '../../../services/api/lead/lead.dto.wesendapi';
import { initImportation } from '../leads/leads.api';

export interface VoiceCampaignFilters {
  phone?: string;
  cpf?: string;
  messageStatus?: LeadMessageStatus;
  proposalStatus?: LeadProposalStatus;
  simulationStatus?: LeadSimulationStatus;
  limit: number;
  lastUpdateStart?: string,
  lastUpdateEnd?: string,
}

export interface VoiceCampaignState {
  loading: boolean;
  error: string | null;
  count: number,
  filters: VoiceCampaignFilters | undefined;
  voiceCampaigns: Array<VoiceCampaign>;
  pagination: PaginatedMetaData | undefined;
  loadingUpdate: boolean;
}

const initialState: VoiceCampaignState = {
  loading: false,
  error: null,
  count: 0,
  filters: undefined,
  voiceCampaigns: [],
  loadingUpdate: false,
  pagination: {
    total: 0,
    lastPage: 0,
    currentPage: 0,
    perPage: 20,
    prev: 0,
    next: 0,
  },
};

export const createVoiceCampaign = createAsyncThunk<string, CreateVoiceCampaignDto>(
  'voiceCampaign/create',
  async (data, { getState, dispatch }) => {
    const result = await wesendApi.voiceCampaign.create(data);
    dispatch(initImportation());
    return result;
  });

export const stopVoiceCampaign = createAsyncThunk<VoiceCampaign, string>(
  'voiceCampaign/stop',
  async (id, { getState, dispatch }) => {
    return await wesendApi.voiceCampaign.stop(id);
  });

export const getVoiceCampaigns = createAsyncThunk<{ voiceCampaigns: Array<VoiceCampaign>, pagination: PaginatedMetaData },{ page: number, filter: VoiceCampaignFilterInput}>(
  'voiceCampaign/list',
  async (params, {dispatch, getState}) => {
    const voiceCampaigns = await wesendApi.voiceCampaign.list({
      ...params.filter,
      page: params.page,
    });
    return { voiceCampaigns: voiceCampaigns.data, pagination: voiceCampaigns.meta };
  },
);

export const createBatchVoiceCampaign = createAsyncThunk<string, { id: string, leads: Array<CreateLeadDto>}>(
  'voiceCampaign/createBatchVoiceCampaign',
  async (_, {dispatch, getState}) => {
    return await wesendApi.typingRobot.createBatchTypingRobot(_.id, _.leads);
  },
);

export const exportVoiceCampaignBatch = createAsyncThunk<string, string>(
  'voiceCampaign/exportBatch',
  async (id, {dispatch, getState}) => {
    const csv = await wesendApi.voiceCampaign.exportVoiceCampaignBatch(id);
    return csv;
  },
);

export const voiceCampaignSlice = createSlice({
  name: 'Channel',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(stopVoiceCampaign.pending, (state) => {
        state.loadingUpdate = true;
        state.error = undefined;
      })
      .addCase(stopVoiceCampaign.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(stopVoiceCampaign.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(createVoiceCampaign.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(createVoiceCampaign.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createVoiceCampaign.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(getVoiceCampaigns.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVoiceCampaigns.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.voiceCampaigns = action.payload.voiceCampaigns;
        state.pagination = action.payload.pagination;
      })
      .addCase(getVoiceCampaigns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createBatchVoiceCampaign.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBatchVoiceCampaign.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(createBatchVoiceCampaign.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(exportVoiceCampaignBatch.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportVoiceCampaignBatch.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(exportVoiceCampaignBatch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export const {updateFilters} = voiceCampaignSlice.actions;

