import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import * as React from 'react';

export function FilterItemInputText(
  {
    label,
    onChange,
    value,
  }: {
    label: string,
    onChange: (v: string) => void,
    value: string;
  },
) {
  return (
    <InputGroup>
      <Input
        maxW={200}
        size={'sm'}
        type='text'
        placeholder={label}
        onChange={(event: any) => onChange(event.target.value)}
        value={value}
      />
    </InputGroup>
  );
}
