import { wesendRequests } from '../wesend.api';
import { Channel, ChannelFilter, ChannelStatus } from '../../../models/channel.model';
import { PaginatedResult } from '../lead/lead.dto.wesendapi';

export const getChannel = async (id: string): Promise<Channel> => {
  try {
    const path = `channel/${id}`;
    const result = await wesendRequests.get(path, {});
    return result;
  } catch (e) {
    throw e;
  }
};

export const getChannels = async (data: ChannelFilter & {
  // orderBy?: UserOrderByInput,
  page?: number,
}): Promise<PaginatedResult<Channel>> => {
  try {
    const path = 'channel';
    const result = await wesendRequests.get(path, data);
    return result;
  } catch (e) {
    throw e;
  }
};

export const removeChannel = async (id: string): Promise<boolean> => {
  try {
    const path = `channel/${id}`;
    const result = await wesendRequests.delete(path);
    return result;
  } catch (e) {
    throw e;
  }
};

export const restartChannel = async (id: string): Promise<boolean> => {
  try {
    const path = `channel/restart/${id}`;
    const result = await wesendRequests.post(path, {});
    return result;
  } catch (e) {
    throw e;
  }
};

export const createChannel = async (channel: Channel): Promise<Channel> => {
  try {
    const path = `channel`;
    const result = await wesendRequests.post(path, channel);
    return result;
  } catch (e) {
    console.log('createChannel', e)
    throw e;
  }
};

export const editChannel = async (channel: Channel): Promise<Channel> => {
  try {
    const path = `channel/${channel.id}`;
    const result = await wesendRequests.put(path, channel);
    return result;
  } catch (e) {
    console.log('createChannel', e)
    throw e;
  }
};
