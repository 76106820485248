import { forwardRef, HStack, HTMLChakraProps, Image, Skeleton, Stack, Text, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { CustomCardProps } from '../../theme/theme';

interface EmptyStateProps extends HTMLChakraProps<'div'> {
  loading?: boolean;
  empty?: boolean;
  text?: string;
  children?: ReactNode;
}

const EmptyState = forwardRef((props, ref) => {
  if (props.loading) {
    return (
      <Stack gap={5}>
        <Skeleton height='20px' />
        <Skeleton height='20px' />
        <Skeleton height='20px' />
        <Skeleton height='20px' />
        <Skeleton height='20px' />
        <Skeleton height='20px' />
        <Skeleton height='20px' />
        <Skeleton height='20px' />
        <Skeleton height='20px' />
      </Stack>
    );
  }
  if (props.empty) {
    return (
      <VStack w={'100%'} mt={20} spacing={2} justifyContent={'center'}>
        <Image src='/icons/empty-state.png' alt='Nenhum' w={'100px'}/>
        <Text fontSize='lg' color={'gray'}>{props.filtering ? props.textFilter : props.text}</Text>
      </VStack>
    );
  } else {
    return props.children;
  }
});

export default EmptyState;
