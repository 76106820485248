import { wesendRequests } from '../wesend.api';
import { CompanyIndicatorsDto, CompanySubscription } from '../../../models/company.model';

export const getCompanySubscription = async (): Promise<CompanySubscription> => {
  try {
    const path = 'company/subscription';
    const result = await wesendRequests.get(path, {});
    return result;
  } catch (e) {
    throw e;
  }
};

export const getCompanyIndicators = async (): Promise<CompanyIndicatorsDto> => {
  try {
    const path = 'company/indicators';
    const result = await wesendRequests.get(path, {});
    return result;
  } catch (e) {
    throw e;
  }
};
