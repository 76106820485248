import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Progress,
  SimpleGrid,
  Spacer,
  Tag,
  TagLabel,
  Text,
  useDisclosure, VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { HSeparator } from '../../../components/separator/Separator';
import { format, parse } from 'date-fns';
import { Mention, MentionsInput } from 'react-mentions';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { ChannelType, ChannelTypeName } from '../../../models/channel.model';
import { useNavigate } from 'react-router-dom';
import {
  CreateLeadDto,
  LeadMessageStatus,
  LeadProposalStatus,
  LeadSimulationStatus,
  statusColor,
} from '../../../models/lead.model';
import { Campaing, CampaingStatusTypeName, CreateCampaingDto } from '../../../models/campaing.model';
import { createCampaing, exportCampaingBatch } from '../../../store/features/campaing/campaing.slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/store';
import { showToast } from '../../../services/toast.service';
import mentionsStyle from '../components/mentions.style';
import { clearResult, getImportStatus } from '../../../store/features/leads/leads.api';
import ModalCreateBatch from '../components/ModalCreateBatch';
import AlertHelper from '../../../components/alertHelper/bankIcon.component';
import { BankType } from '../../../models/robot.model';

interface IModalRobot {
  campaign: Campaing | undefined;
  isOpen: boolean;
  onClose: (updated: boolean) => void;
}

enum MentionsFields {
  FIST_NAME = '{FIST_NAME}',
  CREDIT_VALUE = '{CREDIT_VALUE}',
  LINK = '{LINK}',
}

const TemplateMentions = [
  { id: MentionsFields.FIST_NAME, display: '{PRIMEIRO_NOME}' },
  { id: MentionsFields.CREDIT_VALUE, display: '{VALOR_DISPONÍVEL}' },
  { id: MentionsFields.LINK, display: '{LINK}' },
];

export function ModalCampaignSms({ campaign, isOpen, onClose }: IModalRobot) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { channels: channelsList, loading: loadingChannels } = useAppSelector((state: RootState) => state.channel);
  const { loading, error, loadingUpdate } = useAppSelector((state: RootState) => state.campaing);
  const { importPercent, importing, result } = useAppSelector((state: RootState) => state.leads);
  const { subscription } = useAppSelector((state: RootState) => state.company);
  const [needsLink, setNeedsLink] = useState<boolean>(false);
  const [needsCreditValue, setNeedsCreditValue] = useState<boolean>(false);
  const [balanceWarning, setBalanceWaring] = useState<boolean>(false);
  const [leadsToImport, setLeadsToImport] = useState<Array<CreateLeadDto>>([]);
  const smsBalance = subscription.available.sms - subscription.using.sms;
  const {
    isOpen: isOpenModalCreateBatch,
    onOpen: onOpenModalCreateBatch,
    onClose: onCloseModalCreateBatch,
  } = useDisclosure();
  const [importId, setImportId] = useState<string>(null);

  const { errors, handleSubmit, isValid, handleChange, values, resetForm, setFieldValue, dirty } = useFormik({
    initialValues: {
      name: `SMS ${format(new Date(), 'dd/MM/yyyy HH:mm')}`,
      simulationStatus: LeadSimulationStatus.NEW,
      proposalStatus: LeadProposalStatus.NONE,
      messageStatus: LeadMessageStatus.NONE,
      lastUpdateStart: format(new Date(), 'yyyy-MM-dd'),
      lastUpdateEnd: format(new Date(), 'yyyy-MM-dd'),
      dateStart: format(new Date(), 'yyyy-MM-dd'),
      dateEnd: format(new Date(), 'yyyy-MM-dd'),
      timeStart: format(new Date(new Date().setHours(8, 0, 0, 0)), 'HH:mm'),
      timeEnd: format(new Date(new Date().setHours(17, 0, 0, 0)), 'HH:mm'),
      templates: ['Olá, {PRIMEIRO_NOME}!\n' +
      'Temos uma oferta especial de crédito para você!\nAcesse {LINK} para saber mais.'],
      channels: [],
      leadFilter: {},
      bank: null,
      batchLeads: [],
      channelType: ChannelType.SMS,
      link: '',
    },
    onSubmit: async (values) => {
      if (values.channelType === ChannelType.SMS && !values.link && needsLink) {
        showToast('error', 'Preencha o link ou remova do template!');
        return;
      }
      const campaing: CreateCampaingDto = {
        name: values.name,
        channels: values.channels,
        timeStart: parse(values.timeStart, 'HH:mm', new Date()),
        timeEnd: parse(values.timeEnd, 'HH:mm', new Date()),
        dateStart: parse(values.dateStart, 'yyyy-MM-dd', new Date()),
        dateEnd: parse(values.dateEnd, 'yyyy-MM-dd', new Date()),
        templates: values.templates,
        leadFilter: values.leadFilter,
        bank: values.bank,
        batchLeads: values.batchLeads,
        channelType: values.channelType,
      };
      if (values.link) {
        campaing.link = values.link;
      }
      const result: any = await dispatch(createCampaing(campaing));
      if (result.error) {
        showToast('error', result.error.message);
      } else {
        setImportId(result.payload);
      }
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Campo obrigatório'),
      limit: yup.number().max(15000).min(1),
      link: yup.string().url('Link inválido'),
    }),
  });

  useEffect(() => {
    let importStatusInterval: string | number | NodeJS.Timeout;
    if (importId) {
      importStatusInterval = setInterval(() => {
        console.log('interval')
        dispatch(getImportStatus(importId));
      }, 1000);
    } else {
      clearInterval(importStatusInterval);
    }
    return () => clearInterval(importStatusInterval);
  }, [importId]);

  useEffect(() => {
    if (result) {
      setImportId(null);
      createdCampain();
    }
  }, [result]);

  function createdCampain() {
    showToast('success', 'Campanha criada!');
    navigate('/admin/campains');
    closeModal();
    dispatch(clearResult());
  }

  function updateTemplates(index: number, template: string) {
    values.templates[index] = template;
    setFieldValue('templates', [...values.templates]);
  }

  function removeTemplate(index: number) {
    values.templates.splice(index, 1);
    setFieldValue('templates', [...values.templates]);
  }

  function newTempate() {
    values.templates.push('Olá {PRIMEIRO_NOME}');
    setFieldValue('templates', [...values.templates]);
  }

  useEffect(() => {
    if (error) {
      showToast('error', error);
    }
  }, [error]);

  function closeModal() {
    resetForm();
    onClose(false);
  }

  useEffect(() => {
    if (values.templates && values.templates.some(v => v.includes('{LINK}'))) {
      setNeedsLink(true);
    } else {
      setNeedsLink(false);
    }
    if (values.templates && values.templates.some(v => v.includes('{CREDIT_VALUE}'))) {
      setNeedsCreditValue(true);
    } else {
      setNeedsCreditValue(false);
    }
  }, [values.templates]);

  useEffect(() => {
    let leads = leadsToImport;
    if (needsCreditValue) {
      leads = leads.filter(l => l.credit);
    }
    if (subscription.available.sms && leads.length > smsBalance) {
      leads = leads.splice(0, smsBalance);
      setBalanceWaring(true);
    } else {
      setBalanceWaring(false);
    }
    setFieldValue('batchLeads', leads);
  }, [leadsToImport, needsCreditValue]);

  function setLeads(leads: Array<CreateLeadDto>, bank?: BankType) {
    setLeadsToImport(leads);
    setFieldValue('bank', bank);
  }

  async function baseDownload() {
    const result = await dispatch(exportCampaingBatch(campaign?.id));
    if (result.payload) {
      showToast('success', 'Leads exportados!');
      const a = document.createElement('a');
      // @ts-ignore
      const blob = new Blob([result.payload], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `[wesend] Leads digitacao exportados ${format(new Date(), 'dd/MM/yyyy-HH:mm')}.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }

  function mentions() {
    return [...TemplateMentions];
  }

  return (
    <Drawer
      size={'xl'}
      isOpen={isOpen}
      placement='right'
      onClose={closeModal}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          {campaign?.name || 'Nova campanha'}
          <Tag
            ml={5}
            borderRadius='full'
            variant='solid'
            size={'lg'}
            colorScheme={statusColor[campaign?.status]}
          >
            <TagLabel>{CampaingStatusTypeName[campaign?.status]}</TagLabel>
          </Tag>
        </DrawerHeader>
        <DrawerBody>
          {!campaign?.id ? <Box>
              <SimpleGrid columns={1} spacing={5}>
                <HStack spacing={5}>
                  <FormControl isInvalid={!!errors.name}>
                    <FormLabel>Nome</FormLabel>
                    <Input
                      type={'text'}
                      value={values.name}
                      onChange={handleChange('name')}
                      min={values.lastUpdateEnd}
                    />
                    {!!errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
                  </FormControl>
                  <FormControl>
                    <FormLabel>Canal</FormLabel>
                    <Tag
                      size={'md'}
                      borderRadius='full'
                      variant='solid'
                      colorScheme={statusColor[values.channelType]}
                    >
                      <TagLabel>{ChannelTypeName[values.channelType]}</TagLabel>
                    </Tag>
                    {!!errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
                  </FormControl>
                </HStack>
                <HSeparator />
                <Heading mt={3} size='sm'>Base</Heading>
                <HStack alignItems={'center'}>
                  {!campaign?.id ? <Button colorScheme='blue'
                                           onClick={() => onOpenModalCreateBatch()}>
                    {leadsToImport.length ? 'Substituir' : 'Importar'}
                  </Button> : null}
                  {campaign?.id ? <Button colorScheme='green' isLoading={loading} onClick={baseDownload}>Exportar</Button> : null}
                  <VStack alignItems={'start'}>
                    {leadsToImport.length ?
                    <AlertHelper component={<Text color='gray.700' fontSize={14}>
                      <b>{values.batchLeads.length}</b> de {leadsToImport.length} leads que atendem aos critérios
                  </Text>}/> : null}
                  {needsCreditValue ?
                    <AlertHelper type={'warning'} component={<Text color='gray.700' fontSize={14}>
                      É necessario possuir a coluna Crédito
                    </Text>}/> : null}
                  {balanceWarning ?
                    <AlertHelper type={'warning'} component={<Text color='gray.700' fontSize={14}>
                      Quantidade de leads na base superior ao saldo SMS <b>({smsBalance})</b>
                    </Text>}/> : null}
                  </VStack>
                </HStack>
                <HSeparator />
                <HStack mt={3}>
                  <Heading size='sm'>
                    Mensagem
                  </Heading>
                  <AlertHelper component={<Text color='gray.700' fontSize={14}>
                    Insira o caractere <b style={{ margin: '0px 5px' }}>{'{'}</b> para adicionar variáveis
                  </Text>} />
                </HStack>
                {values.templates.map((t, index) =>
                  <Flex key={index} justifyContent={'center'} alignItems={'center'}>
                    <MentionsInput value={t} onChange={(v) => updateTemplates(index, v.target.value)}
                                   style={mentionsStyle}>
                      <Mention
                        trigger='{'
                        data={mentions()}
                      />
                    </MentionsInput>
                    {!!index && <Button ml={2} colorScheme='red' variant='outline' onClick={() => removeTemplate(index)}>
                      <DeleteIcon />
                    </Button>}
                  </Flex>,
                )}
                <Flex justifyContent={'end'} gap={5}>
                  <AlertHelper message={'Se desejar, adicione um novo template para criar variações da mensagem'} />
                  <Button leftIcon={<AddIcon />} colorScheme='blue' variant='outline' size={'sm'} onClick={newTempate}>
                    Novo
                  </Button>
                </Flex>
                {needsLink ? <>
                  <HSeparator/>
                  <HStack mt={3}>
                    <Heading size='sm'>
                      Link
                    </Heading>
                    <AlertHelper component={<Text color='gray.700' fontSize={14}>
                      Insira o link para compor a mensagem. Para gerar um link Whatsapp entre <Link color={'blue'}
                                                                                                    target='_blank'
                                                                                                    href={'https://api.chatpro.com.br/gerador-de-links'}>aqui</Link>.
                    </Text>} />
                  </HStack>
                  <FormControl isInvalid={!!errors.link}>
                    <Input
                      type={'text'}
                      value={values.link}
                      onChange={handleChange('link')}
                      min={values.lastUpdateEnd}
                      placeholder={'https://wa.me/5548999998888?text=Olá'}
                    />
                    {!!errors.link && <FormErrorMessage>{errors.link}</FormErrorMessage>}
                  </FormControl>
                </> : null}
              </SimpleGrid>
            </Box>
            :
            <SimpleGrid columns={1} spacing={5}>
              <Heading mt={3} size='sm'>Base</Heading>
              <HSeparator />
              <Button w={'120px'} colorScheme='green' isLoading={loading}  onClick={baseDownload}>Exportar</Button>
            </SimpleGrid>
          }
        </DrawerBody>
        <DrawerFooter>
          <Flex w={'100%'} gap={2}>
            {importing ? <Center>
              <Progress colorScheme='blue' h='10px' w='300px'
                        value={importPercent} />
              <Text ml={3}>{importPercent}%</Text>
            </Center> : null}
            <Spacer />
            <HStack>
              <Button isDisabled={loading || importing} colorScheme='gray' mr={3} onClick={() => onClose(false)}>
                Fechar
              </Button>
              <Spacer />
              {!campaign?.id ?
                <Button isLoading={loading || importing} isDisabled={!isValid || !values.batchLeads.length}
                        colorScheme='blue' mr={3}
                        onClick={() => handleSubmit()}>Criar campanha</Button> : null
              }
            </HStack>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
      <ModalCreateBatch
        onClose={onCloseModalCreateBatch}
        onOpen={onOpenModalCreateBatch}
        isOpen={isOpenModalCreateBatch}
        campaingId={campaign?.id}
        onUpload={(leads, bank) => setLeads(leads, bank)}
      />
    </Drawer>
  );
}
