import { LeadFilterInput, PaginatedResult, UserOrderByInput } from './lead.dto.wesendapi';
import { wesendRequests } from '../wesend.api';
import {
  ChangeManyLeadsDto,
  CreateLeadDto, EditManyLeadsDto, EditManyLeadsWithCpfDto, ExportAndEditLeadsDto,
  ImportLeadResponseDto,
  Lead,
  UpdateLeadDto,
} from '../../../models/lead.model';

export const getLeads = async (data: LeadFilterInput & {
  orderBy?: UserOrderByInput,
  page?: number,
}): Promise<PaginatedResult<Lead>> => {
  try {
    const path = 'lead';
    const result = await wesendRequests.get(path, data);
    return result;
  } catch (e) {
    throw e;
  }
};

export const exportLeads = async (data: ExportAndEditLeadsDto): Promise<string> => {
  try {
    const path = 'lead/export';
    const result = await wesendRequests.post(path, data);
    return result;
  } catch (e) {
    throw e;
  }
};

export const importLeads = async (leads: Array<CreateLeadDto>): Promise<string> => {
  try {
    const path = 'lead/import';
    const result = await wesendRequests.post(path, leads);
    return result;
  } catch (e) {
    throw e;
  }
};

export const importStatus = async (id: string): Promise<ImportLeadResponseDto> => {
  try {
    const path = `lead/importStatus/${id}`;
    const result = await wesendRequests.get(path, {});
    return result;
  } catch (e) {
    throw e;
  }
};

export const updateLead = async (lead: UpdateLeadDto): Promise<Lead> => {
  try {
    const path = `lead/${lead.id}`;
    const result = await wesendRequests.put(path, lead);
    return result;
  } catch (e) {
    throw e;
  }
};

export const removeLead = async (id: string): Promise<boolean> => {
  try {
    const path = `lead/${id}`;
    const result = await wesendRequests.delete(path);
    return result;
  } catch (e) {
    throw e;
  }
};

export const removeMany = async (input: ChangeManyLeadsDto): Promise<boolean> => {
  try {
    const path = `lead/removeMany`;
    const result = await wesendRequests.post(path, input);
    return result;
  } catch (e) {
    throw e;
  }
};

export const editMany = async (input: EditManyLeadsDto): Promise<boolean> => {
  try {
    const path = `lead/editMany`;
    const result = await wesendRequests.post(path, input);
    return result;
  } catch (e) {
    throw e;
  }
};

export const editManyWithCpf = async (input: EditManyLeadsWithCpfDto): Promise<boolean> => {
  try {
    const path = `lead/editManyWithCpf`;
    const result = await wesendRequests.post(path, input);
    return result;
  } catch (e) {
    throw e;
  }
};


