import { Box, Button, HStack, SimpleGrid, Spacer, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import RobotsTable from './components/RobotsTable';
import { ModalRobot } from './components/ModalRobot';
import { Robot, RobotFilterInput } from '../../models/robot.model';
import { AddIcon } from '@chakra-ui/icons';
import { FiRefreshCcw } from 'react-icons/fi';
import { getRobots } from '../../store/features/robots/robots.slice';
import EmptyState from '../../components/emptyState/emptyState.component';
import { canCreateModule } from '../../store/features/company/company.slice';
import { CompanySubscriptionModuleType } from '../../models/company.model';

export default function BalanceRobots() {

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const [selectedRobot, setSelectedRobot] = useState<Robot | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { robots, loading, error, pagination } = useAppSelector((state: RootState) => state.robot);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<RobotFilterInput>({});
  const [isFiltering, setIsfiltering] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getRobots({ page, filter }));
  }, [filter, page]);

  function updateFilter(newFilter: RobotFilterInput) {
    setFilter(newFilter);
    if (newFilter && !Object.keys(newFilter).length) {
      setIsfiltering(false);
    }
  }

  useEffect(() => {
    if (selectedRobot)
      onToggle();
  }, [selectedRobot]);

  function closeDrawerRobot(updated: boolean) {
    setSelectedRobot(undefined);
    onClose();
    if (updated) {
      dispatch(getRobots({ page, filter }));
    }
  }

  function refresh() {
    dispatch(getRobots({ page, filter }));
  }

  function create() {
    dispatch(canCreateModule({
        module: CompanySubscriptionModuleType.BALANCE_ROBOT,
        fn: () => {
          onToggle();
        },
      },
    ));
  }

  return (
    <Box mt={{ base: '120px', md: '80px' }}>
      <HStack alignItems={'center'} mb={5} gap={10}>
        {!isFiltering ? <Button ml={3} rightIcon={<FiRefreshCcw />} colorScheme='blue' variant='link' onClick={refresh}>
          Atualizar
        </Button> : null}
        <Spacer/>
        {/*<RobotFilter onFilter={updateFilter} loading={loading} onClick={() => setIsfiltering(true)} />*/}
        {!isFiltering ? <Button rightIcon={<AddIcon />} colorScheme='blue' onClick={create} size={'sm'}>
          Novo
        </Button> : null}
      </HStack>
      <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <EmptyState text={'Nenhum robô criado'} empty={robots && !robots.length} loading={loading} filtering={isFiltering} textFilter={'Nenhum robô encontrado'}>
          <RobotsTable
            pagination={pagination}
            tableData={robots || []}
            onSelect={(l) => setSelectedRobot(l)}
            onPageChange={v => setPage(v)}
            loading={loading}
          />
        </EmptyState>
      </SimpleGrid>
      {isOpen ? <ModalRobot robot={selectedRobot} isOpen={isOpen}
                            onClose={(updated: boolean) => closeDrawerRobot(updated)} /> : null}
    </Box>
  );
}
