import { CreateLeadDto, LeadMessageStatus, LeadProposalStatus, LeadSimulationStatus } from './lead.model';
import { LeadFilterInput } from '../services/api/lead/lead.dto.wesendapi';
import { BankType } from './robot.model';
import { ChannelType } from './channel.model';

export class CreateCampaingDto {
  name: string;
  channelType: ChannelType;
  leadFilter: LeadFilterInput;
  templates: Array<string>;
  channels: Array<string>;
  dateStart: Date;
  dateEnd: Date;
  timeStart: Date;
  timeEnd: Date;
  bank: BankType | null;
  batchLeads: Array<CreateLeadDto>;
  link?: string;
}

export interface LeadsCampainFilters {
  simulationStatus: LeadSimulationStatus;
  proposalStatus: LeadProposalStatus;
  messageStatus: LeadMessageStatus;
  lastUpdateStart: string;
  lastUpdateEnd: string;
  limit: number;
}

export enum CampaingStatus {
  NEW = 'NEW',
  CANCELED = 'CANCELED',
  RUNNING = 'RUNNING',
  FINALIZED = 'FINALIZED',
}

export const statusCampainColor = {
  [CampaingStatus.NEW]: 'gray',
  [CampaingStatus.CANCELED]: 'red',
  [CampaingStatus.RUNNING]: 'blue',
  [CampaingStatus.FINALIZED]: 'green',
};

export const CampaingStatusTypeName = {
  [CampaingStatus.NEW]: 'NOVA',
  [CampaingStatus.CANCELED]: 'CANCELADA',
  [CampaingStatus.RUNNING]: 'EM EXECUÇÃO',
  [CampaingStatus.FINALIZED]: 'FINALIZADA',
};

export interface CampaignFilterInput {
  status?: Array<CampaingStatus>;
}

export class Campaing {
  constructor() {
  }

  id: string;
  name: string;
  channelType: ChannelType;
  createdAt: Date;
  status: CampaingStatus;
  leadsFilter: LeadFilterInput;
  dateStart: string;
  dateEnd: string;
  timeStart: string;
  timeEnd: string;
  templates: Array<string>;
  channels: Array<string>;
  countScheduleddMessages: number;
  countSentMessages: number;
  countLinkClick: number;
  countReceivedMessages: number;
  progress: number;
}
