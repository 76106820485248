import { Action, Fab } from 'react-tiny-fab';
import { MdAdd, MdPerson } from 'react-icons/md';
import 'react-tiny-fab/dist/styles.css';
import { CSSProperties, ReactNode } from 'react';

const mainButtonStyles: CSSProperties = {
  color: 'red',
  backgroundColor: 'blue'
};

const actionButtonStyles: CSSProperties = {
  color: 'red',
};

export interface IFabActions {
  label: string,
  icon: ReactNode,
  onClick: () => void,
  style: CSSProperties
}
export interface IFabParameters {
  onOpen: () => void,
  actions: Array<IFabActions>
}

export default function FabComponent({ onOpen, actions }: IFabParameters) {
  return (
    <Fab
      mainButtonStyles={mainButtonStyles}
      // actionButtonStyles={actionButtonStyles}
      // style={style}
      icon={<MdPerson color={'white'}/>}
      // event={event}
      alwaysShowTitle={true}
      onClick={onOpen}
    >
      // The Action components are the "buttons" that appear when the Fab is open. You can use the out-of-the-box Action
      // component or you can use a custom component of any type and style it any way that you'd like. The "text" prop
      // is the popup label that appears when the Action component is hovered.
      {actions.map(a =>
        <Action
          style={a.style}
          text={a.label}
          onClick={a.onClick}
        >
          {a.icon}
        </Action>,
      )}
    </Fab>);
}
