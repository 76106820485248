import * as React from 'react';
import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import ConfirmationButton from '../../../components/buttons/RemoveConfirmation';
import { InputNumber } from '../../../components/inputNumber/inputNumber.component';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/store';
import { editMany } from '../../../store/features/leads/leads.api';
import {
  ChangeManyLeadsType,
  LeadMessageStatus,
  LeadProposalStatus, LeadSimulationStatus,
  messageStatusName,
  proposalStatusName, simulationStatusName,
} from '../../../models/lead.model';
import { LeadFilterInput } from '../../../services/api/lead/lead.dto.wesendapi';
import { showToast } from '../../../services/toast.service';
import { useFormik } from 'formik';
import { HSeparator } from '../../../components/separator/Separator';
import { BankType, BankTypeName } from '../../../models/robot.model';

export interface IModalSelectedLeads {
  totalCount: number;
  selectedLeads: Array<string>;
  filter: LeadFilterInput;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onUpdate: () => void;
}

export default function ModalEditLeads({ selectedLeads, isOpen, onOpen, onClose, totalCount, filter, onUpdate }: IModalSelectedLeads) {

  const dispatch = useAppDispatch();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [selectionType, setSelectionType] = useState<ChangeManyLeadsType>('selected');
  const [count, setCount] = useState(selectedLeads.length);
  const { loadingUpdate } = useAppSelector((state: RootState) => state.leads);

  const { handleSubmit, handleChange, values} = useFormik({
    initialValues: {
      lastMessageStatus: 'NOTHING',
      lastProposalStatus: 'NOTHING',
      lastSimulationStatus: 'NOTHING',
      bank: '',
    },
    onSubmit: async (values) => {
      if (values?.lastSimulationStatus !== 'NOTHING' && !values?.bank) {
        showToast('error', 'Preencha o Banco');
        return;
      }
      const result = await dispatch(editMany({
          selection: {
            count,
            filter,
            ids: selectedLeads,
            type: selectionType,
          },
          lastMessageStatus: values.lastMessageStatus as LeadSimulationStatus & 'NOTHING',
          lastProposalStatus: values.lastProposalStatus as LeadProposalStatus & 'NOTHING',
          lastSimulationStatus: values.lastSimulationStatus as LeadSimulationStatus & 'NOTHING',
          bank: values?.bank as BankType,
        }),
      );
      if (result.payload) {
        showToast('success', 'Leads editados!');
        onClose();
        onUpdate();
      }
    },
  });

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar leads</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <RadioGroup onChange={(v) => setSelectionType(v as ChangeManyLeadsType)} value={selectionType}>
              <Stack direction='column' gap={5}>
                <Radio value='selected'><b>{selectedLeads.length}</b> leads selecionados</Radio>
                {totalCount <= 1000 ?
                  <Radio value='all' disabled={totalCount > 1000}>Todos <b>({totalCount})</b></Radio> : null}
                <Radio value='count'>Definir quantidade</Radio>
              </Stack>
              {selectionType === 'count' ? <Box mt={5}>
                <FormControl>
                  <InputNumber min={1}
                               max={totalCount}
                               value={count}
                               onChange={(e) => setCount(e)} />
                </FormControl>
              </Box> : null}
            </RadioGroup>
            <HSeparator mt={5} mb={5}/>
            <HStack alignItems={'center'} mb={5}>
              <Text as={'b'} textAlign={'right'} w={200}>Última mensagem:</Text>
              <Select maxW={300} placeholder="Última mensagem" onChange={handleChange('lastMessageStatus')}
                      value={values?.lastMessageStatus}>
                <option value={'NOTHING'}>Não alterar</option>
                {Object.values(LeadMessageStatus).map(s =>
                  <option value={s}>{messageStatusName[s]}</option>
                )}
              </Select>
            </HStack>
            <HStack alignItems={'center'} mb={5}>
              <Text as={'b'} textAlign={'right'} w={200}>Última proposta:</Text>
              <Select maxW={300} placeholder="Última proposta" onChange={handleChange('lastProposalStatus')}
                      value={values?.lastProposalStatus}>
                <option value={'NOTHING'}>Não alterar</option>
                {Object.values(LeadProposalStatus).map(s =>
                  <option value={s}>{proposalStatusName[s]}</option>
                )}
              </Select>
            </HStack>
            <HStack alignItems={'center'} mb={5}>
              <Text as={'b'} textAlign={'right'} w={200}>Última simulação:</Text>
              <Select  maxW={300} placeholder='Última proposta' onChange={handleChange('lastSimulationStatus')}
                      value={values?.lastSimulationStatus}>
                <option value={'NOTHING'}>Não alterar</option>
                {Object.values(LeadSimulationStatus).filter(s => s !== LeadSimulationStatus.CREDIT).map(s =>
                  <option value={s}>{simulationStatusName[s]}</option>,
                )}
              </Select>
            </HStack>
            {values?.lastSimulationStatus !== 'NOTHING' ? <HStack alignItems={'center'}>
              <Text as={'b'} textAlign={'right'} w={200}>Banco:</Text>
              <FormControl isInvalid={values?.lastSimulationStatus !== 'NOTHING' && !values?.bank}>
                <Select
                  maxW={300}
                  placeholder={'Selecione'}
                  onChange={handleChange('bank')}
                  value={values?.bank}
                >
                  {Object.values(BankType).map(s =>
                    <option value={s}>{BankTypeName[s]}</option>,
                  )}
                </Select>
              </FormControl>
            </HStack> : null}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancelar</Button>
          <ConfirmationButton onConfirm={handleSubmit}>
            <Button colorScheme='green' size={'md'} isLoading={loadingUpdate}>
              Editar
            </Button>
          </ConfirmationButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
