import { Alert, AlertDescription, AlertIcon, Flex, FormControl, HStack, Input, Switch, Text } from '@chakra-ui/react';
import React from 'react';
import { format, parse } from 'date-fns';

interface IInputCheckTimeRangeValue {
  enabled: boolean;
  startTime: Date;
  endTime: Date;
}

interface IInputCheckTimeRangeComponent {
  error?: string;
  onChange: (value: IInputCheckTimeRangeValue) => void;
  label?: string;
  disabled?: boolean;
  witdh?: number;
  value: IInputCheckTimeRangeValue;
}

export function InputCheckTimeRange(
  {
    error,
    onChange,
    value,
    label,
    disabled,
    witdh,
  }: IInputCheckTimeRangeComponent) {
  console.log('v', value)
  return (
    <HStack alignItems={'center'}>
      {label ? <Text as={'b'} textAlign={'right'} w={200}>{label}</Text> : null}
      <FormControl isInvalid={!!error} maxW={100}>
        <Switch
          size='lg'
          isChecked={value.enabled}
          isDisabled={!!disabled}
          onChange={(e) => {
            onChange({
              enabled: e.target.checked,
              startTime: value.startTime,
              endTime: value.endTime,
            });
          }}
        />
      </FormControl>
      {value.enabled ?
      <Flex align={'center'} gap={5}>
        <Input
          placeholder='Selecione'
          type='time'
          value={format(new Date(value.startTime), 'HH:mm')}
          onChange={(event: any) => {
            onChange({
              enabled: value.enabled,
              startTime: parse(event.target.value, 'HH:mm', new Date()),
              endTime: value.endTime,
            });
          }}
        />
        <Text>
          até
        </Text>
        <Input
          placeholder='Selecione'
          type='time'
          value={format(new Date(value.endTime), 'HH:mm')}
          onChange={(event: any) => {
            console.log(event.target.value)
            onChange({
              enabled: value.enabled,
              startTime: value.startTime,
              endTime: parse(event.target.value, 'HH:mm', new Date()),
            });
          }
          }
        />
      </Flex> : null}
      {!!error && <Alert status='error' maxW={250}>
        <AlertIcon />
        <AlertDescription fontSize={'sm'}>{error}</AlertDescription>
      </Alert>}
    </HStack>
  );
}
