import { LeadSimulationStatus } from './lead.model';
import { BankType } from './robot.model';

export enum SimulationType {
  MANUAL= 'MANUAL',
  ROBOT = 'ROBOT',
  IMPORTATION = 'IMPORTATION',
};

export const SimulationTypeName = {
  [SimulationType.IMPORTATION]: 'IMPORTAÇÃO',
  [SimulationType.ROBOT]: 'ROBÔ',
  [SimulationType.MANUAL]: 'MANUAL',
};

export interface LastSimulation {
    id: string
    status: LeadSimulationStatus
    creditValue: number
    leadId: string
    companyId: string
    type: SimulationType
    bank: BankType
    createdAt: Date
    updatedAt: Date
    robotId: string | null
    deleted: boolean
    deletedAt: Date | null
}
