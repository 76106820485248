import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { wesendApi } from '../../../services/api/wesend.api';
import { PaginatedMetaData } from '../../../services/api/lead/lead.dto.wesendapi';
import { Robot, RobotFilterInput, RobotStatusType } from '../../../models/robot.model';
import { CreateLeadDto, ImportLeadResponseDto } from '../../../models/lead.model';
import { showToast } from '../../../services/toast.service';
import { initImportation, leadsSlice } from '../leads/leads.api';
import { getCompanySubscription } from '../company/company.slice';

export interface RobotsState {
  robots: Array<Robot>;
  loading: boolean;
  loadingUpdate: boolean;
  loadingImporting: boolean;
  error: string | null;
  pagination: PaginatedMetaData | undefined;
  importPercent: number;
  importResult: ImportLeadResponseDto | null;
}

const initialState: RobotsState = {
  robots: [],
  importResult: null,
  importPercent: 0,
  loading: false,
  error: null,
  loadingUpdate: false,
  loadingImporting: false,
  pagination: {
    total: 0,
    lastPage: 0,
    currentPage: 0,
    perPage: 20,
    prev: 0,
    next: 0,
  },
};

export const getRobots = createAsyncThunk<{ robots: Array<Robot>, pagination: PaginatedMetaData },{ page: number, filter: RobotFilterInput}>(
  'robot/list',
  async (params, {dispatch, getState}) => {
    const robots = await wesendApi.robot.list({
      ...params.filter,
      page: params.page,
    });
    return { robots: robots.data, pagination: robots.meta };
  },
);

export const removeRobot = createAsyncThunk<{ success: boolean },{ id: string }>(
  'robot/remove',
  async (params, {dispatch, getState}) => {
    await wesendApi.robot.delete(params.id);
    dispatch(getCompanySubscription());
    return { success: true };
  },
);

export const changeStatus = createAsyncThunk<{ success: boolean },{ id: string, status: RobotStatusType }>(
  'robot/changeStatus',
  async (params, {dispatch, getState}) => {
    await wesendApi.robot.changeStatus(params.id, params.status);
    return { success: true };
  },
);

export const createRobot = createAsyncThunk<Robot, Robot>(
  'robot/create',
  async (robot, {dispatch, getState}) => {
    dispatch(initImportation());
    dispatch(getCompanySubscription());
    return await wesendApi.robot.create(robot);
  },
);

export const editRobot = createAsyncThunk<Robot, Robot>(
  'robot/edit',
  async (robot, {dispatch, getState}) => {
    dispatch(initImportation());
    return await wesendApi.robot.editRobot(robot);
  },
);

export const createBatchRobot = createAsyncThunk<string, { id: string, leads: Array<CreateLeadDto>}>(
  'robot/createBatchRobot',
  async (_, {dispatch, getState}) => {
    return await wesendApi.robot.createBatchRobot(_.id, _.leads);
  },
);

export const exportBatch = createAsyncThunk<string, string>(
  'robot/exportBatch',
  async (id, {dispatch, getState}) => {
    const csv = await wesendApi.robot.exportBatch(id);
    return csv;
  },
);

export const getImportStatus = createAsyncThunk<ImportLeadResponseDto, string>(
  'robot/importStatus',
  async (importId, { getState }) => {
    return await wesendApi.lead.importStatus(importId);
  });

export const robotSlice = createSlice({
  name: 'Robots',
  initialState,
  reducers: {
    clearResult: (state) => {
      state.importResult = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRobots.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRobots.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.robots = action.payload.robots;
        state.pagination = action.payload.pagination;
      })
      .addCase(getRobots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(removeRobot.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(removeRobot.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(removeRobot.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(changeStatus.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(changeStatus.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(changeStatus.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(createRobot.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(createRobot.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(createRobot.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(editRobot.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(editRobot.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(editRobot.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(createBatchRobot.pending, (state) => {
        state.loadingImporting = true;
      })
      .addCase(createBatchRobot.fulfilled, (state, action) => {
        state.error = null;
      })
      .addCase(createBatchRobot.rejected, (state, action) => {
        state.loadingImporting = false;
        state.error = action.error.message;
      })
      .addCase(exportBatch.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(exportBatch.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(exportBatch.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(getImportStatus.pending, (state) => {
      })
      .addCase(getImportStatus.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingImporting = false;
      })
      .addCase(getImportStatus.fulfilled, (state, action) => {
        state.error = null;
        state.importPercent = parseInt(String(action.payload.completed / action.payload.size * 100), 10);
        if (state.importPercent === 100) {
          state.importResult = action.payload;
          showToast('success', 'Base importada!');
          state.loadingImporting = false;
        }
      })
  },
});

export const {clearResult} = robotSlice.actions;
