import { wesendRequests } from '../wesend.api';
import { Robot } from '../../../models/robot.model';
import { CampaignFilterInput, Campaing, CreateCampaingDto } from '../../../models/campaing.model';
import { PaginatedResult } from '../lead/lead.dto.wesendapi';
import { CreateLeadDto } from '../../../models/lead.model';

export const getCampaigns = async (data: CampaignFilterInput & {
  // orderBy?: UserOrderByInput,
  page?: number,
}): Promise<PaginatedResult<Campaing>> => {
  try {
    const path = 'campaign';
    const result = await wesendRequests.get(path, data);
    return result;
  } catch (e) {
    throw e;
  }
};

export const createCampaign = async (campaign: CreateCampaingDto): Promise<string> => {
  try {
    const path = `campaign`;
    const result = await wesendRequests.post(path, campaign);
    return result;
  } catch (e) {
    console.log('createCampaign', e)
    throw e;
  }
};

export const stopCampaign = async (id: string): Promise<Campaing> => {
  try {
    const path = `campaign/${id}/stop`;
    const result = await wesendRequests.post(path, {});
    return result;
  } catch (e) {
    console.log('stopCampaign', e)
    throw e;
  }
};

export const createBatchCampaing = async (campaingId: string, leads: Array<CreateLeadDto>): Promise<string> => {
  try {
    const path = `campaign/importLeads/${campaingId}`;
    const result = await wesendRequests.post(path, leads);
    return result;
  } catch (e) {
    console.log('createTypingRobot', e)
    throw e;
  }
};

export const exportCampaingBatch = async (campaingId: string): Promise<string> => {
  try {
    const path = `campaign/exportBatch/${campaingId}`;
    const result = await wesendRequests.post(path, {});
    return result;
  } catch (e) {
    console.log('createTypingRobot', e)
    throw e;
  }
};
