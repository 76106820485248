import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import { closeSubscriptionAlertModal } from '../../store/features/company/company.slice';
import { WA_SUPPORT_LINK } from '../../models/company.model';

export function SubscriptionAlert() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const { isOpenSubscriptionAlertModal, currentModuleName } = useAppSelector((state: RootState) => state.company);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpenSubscriptionAlertModal) {
      onOpen();
    }
  }, [isOpenSubscriptionAlertModal]);

  useEffect(() => {
    if (!isOpen) {
      dispatch(closeSubscriptionAlertModal(true));
    }
  }, [isOpen]);

  function support() {
    window.open(WA_SUPPORT_LINK, "_blank");
  }

  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Ops... 😫</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            O limite do módulo <b>{currentModuleName}</b> já foi atingido!
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme='blue' variant={'link'} onClick={support}>
              Contatar suporte
            </Button>
            <Spacer/>
            <Button colorScheme='blue' onClick={onClose}>
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
