import { LastSimulation, SimulationType } from './simulation.model';
import { RobotStatusType, BankType } from './robot.model';
import { ChannelStatus, ChannelType } from './channel.model';
import { Campaing, CampaingStatus } from './campaing.model';
import { LeadFilterInput } from '../services/api/lead/lead.dto.wesendapi';

export enum LeadSimulationStatus {
  FAIL = 'FAIL',
  NEW = 'NEW',
  CREDIT = 'CREDIT',
  WITHOUT_PERMISSION = 'WITHOUT_PERMISSION',
  NO_CREDIT = 'NO_CREDIT',
  EXTERN_SIMULATION = 'EXTERN_SIMULATION',
}

export enum LeadMessageStatus {
  NONE = 'NONE',
  SCHEDULED = 'SCHEDULED',
  ANSWERED = 'ANSWERED',
  RECEIVED = 'RECEIVED',
  PROCESSING = 'PROCESSING',
}

export enum LeadProposalStatus {
  NONE = 'NONE',
  IN_PROPOSAL = 'IN_PROPOSAL',
  DENIED = 'DENIED',
  CONTRACTED = 'CONTRACTED',
  LINK = 'LINK',
  SENT = 'SENT',
}

export const statusColor = {
  [ChannelType.SMS]: 'blue',
  [ChannelType.WHATSAPP]: 'green',

  [LeadSimulationStatus.NEW]: 'gray',
  [LeadMessageStatus.NONE]: 'gray',
  [LeadProposalStatus.NONE]: 'gray',

  [CampaingStatus.NEW]: 'gray',
  [CampaingStatus.RUNNING]: 'blue',
  [CampaingStatus.FINALIZED]: 'green',
  [CampaingStatus.CANCELED]: 'red',

  [LeadMessageStatus.RECEIVED]: 'blue',

  [LeadSimulationStatus.FAIL]: 'red',
  [LeadSimulationStatus.EXTERN_SIMULATION]: 'gray',
  [LeadProposalStatus.DENIED]: 'yellow',

  [LeadSimulationStatus.NO_CREDIT]: 'yellow',
  [LeadSimulationStatus.WITHOUT_PERMISSION]: 'yellow',
  [LeadMessageStatus.SCHEDULED]: 'yellow',
  [LeadProposalStatus.IN_PROPOSAL]: 'yellow',
  [LeadMessageStatus.PROCESSING]: 'yellow',

  [LeadSimulationStatus.CREDIT]: 'green',
  [LeadMessageStatus.ANSWERED]: 'green',
  [LeadProposalStatus.CONTRACTED]: 'green',
  [LeadProposalStatus.LINK]: 'blue',
  [LeadProposalStatus.SENT]: 'green',

  [RobotStatusType.RUNNING]: 'green',
  [RobotStatusType.ERROR]: 'red',
  [RobotStatusType.STOPPED]: 'yellow',
  [RobotStatusType.CREATNG]: 'gray',
  [RobotStatusType.BANNED_IP]: 'red',
  [RobotStatusType.LOGIN_ERROR]: 'red',
  [RobotStatusType.FINISHED]: 'blue',

  [ChannelStatus.ONLINE]: 'green',
  [ChannelStatus.OFFLINE]: 'red',
};

export const simulationStatusName = {
  [LeadSimulationStatus.FAIL]: 'FALHA',
  [LeadSimulationStatus.NEW]: 'NOVO',
  [LeadSimulationStatus.CREDIT]: 'CRÉDITO',
  [LeadSimulationStatus.NO_CREDIT]: 'INDISPONÍVEL',
  [LeadSimulationStatus.WITHOUT_PERMISSION]: 'AUTORIZAR',
  [LeadSimulationStatus.EXTERN_SIMULATION]: 'SIMULAÇÃO EXTERNA',
};

export const messageStatusName = {
  [LeadMessageStatus.NONE]: 'NOVO',
  [LeadMessageStatus.RECEIVED]: 'RECEBIDA',
  [LeadMessageStatus.ANSWERED]: 'RESPONDIDA',
  [LeadMessageStatus.SCHEDULED]: 'AGENDADA',
  [LeadMessageStatus.PROCESSING]: 'PROCESSANDO',
};

export const proposalStatusName = {
  [LeadProposalStatus.NONE]: 'NOVO',
  [LeadProposalStatus.IN_PROPOSAL]: 'PROPOSTA',
  [LeadProposalStatus.DENIED]: 'NEGADA',
  [LeadProposalStatus.CONTRACTED]: 'CONTRATADA',
  [LeadProposalStatus.LINK]: 'LINK',
  [LeadProposalStatus.SENT]: 'ENVIADA',
};

export const channelStatusName = {
  [ChannelStatus.ONLINE]: 'ONLINE',
  [ChannelStatus.OFFLINE]: 'OFFLINE',
};

export interface Simulation {
  bank: string;
  situation: string;
  credit: number;
  url: string;
}

export interface UmberData {
  contactId: string;
}

export class Lead {
  constructor() {
  }

  id: string;
  cpf: string;
  phone: string;
  name: string;
  photo: string;
  companyId: string;
  lastCreditValue: number;
  lastSimulationDate: Date | null;
  lastSimulationType: SimulationType | null;
  lastSimulationProductType: BankType | null;
  lastSimulationStatus: LeadSimulationStatus;
  lastMessageStatus: LeadMessageStatus;
  lastMessageDate: Date | null;
  lastProposalStatus: LeadProposalStatus;
  lastProposalDate: Date | null;
  createdAt: Date;
  updatedAt: Date;
  deleted: boolean;
  deletedAt: Date | null;
  lastSimulations: Array<LastSimulation>;
}

export class CreateLeadDto {
  name: string;
  cpf: string;
  phone: string;
  credit?: number;
  lastMessageDate?: string;
  bank?: BankType;
  sexo?: string | null;
  estadoCivil?: number | null;
  dataNascimento?: string | null;
  rg?: string | null;
  estadoRg?: string | null;
  orgaoEmissor?: string | null;
  dataExpedicao?: string | null;
  estadoNatural?: string | null;
  cidadeNatural?: string | null;
  nacionalidade?: number | null;
  paisOrigem?: string | null;
  renda?: string | null;
  cep?: string | null;
  endereco?: string | null;
  numero?: number | null;
  complemento?: string | null;
  bairro?: string | null;
  cidade?: string | null;
  estado?: string | null;
  nomeMae?: string | null;
  nomePai?: string | null;
  valorPatrimonio?: number | null;
  clienteIletradoImpossibilitado?: string | null;
  banco?: number | null;
  agencia?: number | null;
  conta?: string | null;
  tipoConta?: string | null;
}

export class UpdateLeadDto {
  id: string;
  name?: string;
  phone?: string;
  messageStatus?: LeadMessageStatus;
  proposalStatus?: LeadProposalStatus;
}

export interface ImportLeadResponseDto {
  created: number;
  error: number;
  updated: number;
  ignored: number;
  size: number;
  completed: number;
}

export type ChangeManyLeadsType = 'all' | 'selected' | 'count';
export class ChangeManyLeadsDto {
  ids: Array<string>;
  filter: LeadFilterInput;
  count: number;
  type: ChangeManyLeadsType;
}

export class EditManyLeadsDto {
  selection: ChangeManyLeadsDto;
  lastProposalStatus: LeadProposalStatus & 'NOTHING';
  lastMessageStatus: LeadMessageStatus & 'NOTHING';
  lastSimulationStatus: LeadSimulationStatus & 'NOTHING';
  bank?: BankType;
}

export class EditManyLeadsWithCpfDto {
  cpfs: Array<string>;
  lastProposalStatus: LeadProposalStatus & 'NOTHING';
  lastMessageStatus: LeadMessageStatus & 'NOTHING';
  lastSimulationStatus: LeadSimulationStatus & 'NOTHING';
  bank?: BankType;
}

export class ExportAndEditLeadsDto {
  ids: Array<string>;
  filter: LeadFilterInput;
  count: number;
  type: ChangeManyLeadsType;
  lastProposalStatus: LeadProposalStatus & 'NOTHING';
  lastMessageStatus: LeadMessageStatus & 'NOTHING';
  lastSimulationStatus: LeadSimulationStatus & 'NOTHING';
}
