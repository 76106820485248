import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { wesendApi } from '../../../services/api/wesend.api';
import {
  CompanyIndicatorsDto,
  CompanySubscription,
  CompanySubscriptionModuleTypeName,
} from '../../../models/company.model';

export interface CompanyState {
  subscription: CompanySubscription;
  loadingSubscription: boolean;
  indicators: CompanyIndicatorsDto,
  loadingIndicators: boolean;
  isOpenSubscriptionAlertModal: boolean;
  currentModuleName: string;
  error: string | null;
}

const initialState: CompanyState = {
  subscription: null,
  currentModuleName: null,
  indicators: null,
  loadingIndicators: false,
  loadingSubscription: false,
  isOpenSubscriptionAlertModal: false,
  error: null,
};

export const getCompanySubscription = createAsyncThunk<CompanySubscription>(
  'company/subscription',
  async (params) => {
    return await wesendApi.company.getCompanySubscription();
  });

export const getCompanyIndicators = createAsyncThunk<CompanyIndicatorsDto>(
  'company/indicators',
  async (params) => {
    return await wesendApi.company.getCompanyIndicators();
  });

export const companySlice = createSlice({
  name: 'Company',
  initialState,
  reducers: {
    canCreateModule(state, action) {
      console.log(state.subscription)
      // @ts-ignore
      state.currentModuleName = CompanySubscriptionModuleTypeName[action.payload.module];
      // @ts-ignore
      if (state.subscription.using[action.payload.module] >= state.subscription.available[action.payload.module]) {
        state.isOpenSubscriptionAlertModal = true;
      } else {
        action.payload.fn();
      }
    },
    closeSubscriptionAlertModal(state, action) {
      state.isOpenSubscriptionAlertModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyIndicators.pending, (state) => {
        state.loadingIndicators = true;
      })
      .addCase(getCompanyIndicators.fulfilled, (state, action) => {
        state.error = null;
        state.loadingIndicators = false;
        state.indicators = action.payload;
      })
      .addCase(getCompanyIndicators.rejected, (state, action) => {
        state.loadingIndicators = false;
        state.error = action.error.message;
      })
      .addCase(getCompanySubscription.pending, (state) => {
        state.loadingSubscription = true;
      })
      .addCase(getCompanySubscription.fulfilled, (state, action) => {
        state.error = null;
        state.loadingSubscription = false;
        state.subscription = action.payload;
      })
      .addCase(getCompanySubscription.rejected, (state, action) => {
        state.loadingSubscription = false;
        state.error = action.error.message;
      });
  },
});

export const { canCreateModule, closeSubscriptionAlertModal } = companySlice.actions;
