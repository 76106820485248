import * as React from 'react';
import { statusColor } from '../../models/lead.model';
import Filter from '../filter';
import { AddedFilterType, FilterItem } from '../filter/filter.constants';
import { CampaignFilterInput, CampaingStatus, CampaingStatusTypeName } from '../../models/campaing.model';

export type CampaignFilterParameters =
'status'
 ;

export type AddedFilter = { [key in Partial<CampaignFilterParameters>]: FilterItem };

const options: AddedFilter =
  {
    status: {
      key: 'status',
      label: 'Status',
      type: 'options',
      options: Object.entries(CampaingStatus).map(
        ([label, value]) => {
          return {
            value: value,
            label: CampaingStatusTypeName[value],
            colorScheme: statusColor[value],
          };
        },
      ),
    },
  };


export default function CampaignFilter({ onFilter, loading, onClick}: { onFilter: (f: CampaignFilterInput) => void, loading: boolean, onClick: () => void }) {

  function applyFilters(filters: Array<AddedFilterType>) {
    const filterCompose: CampaignFilterInput = {};
    for (const filter of filters) {
      switch (filter.key as CampaignFilterParameters) {
        case 'status':
          filterCompose.status = filter.values.map(v => v.value);
          break;
        default:
          break;
      }
    }
    onFilter(filterCompose);
  }

  return (
    <Filter
      options={options}
      onApply={applyFilters}
      loading={loading}
      onClick={onClick}
    />
  );
}
