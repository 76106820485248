import { Avatar, Box, Flex, FormLabel, Icon, Select, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import Usa from 'assets/img/dashboards/usa.png';
import MiniCalendar from 'components/calendar/MiniCalendar';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy, MdPerson } from 'react-icons/md';
import CheckTable from 'views/admin/rtl/components/CheckTable';
import ComplexTable from 'views/admin/default/components/ComplexTable';
import DailyTraffic from 'views/admin/default/components/DailyTraffic';
import PieCard from 'views/admin/default/components/PieCard';
import Tasks from 'views/admin/default/components/Tasks';
import TotalSpent from 'views/admin/default/components/TotalSpent';
import WeeklyRevenue from 'views/admin/default/components/WeeklyRevenue';
import tableDataCheck from 'views/admin/default/variables/tableDataCheck';
import tableDataComplex from 'views/admin/default/variables/tableDataComplex';
import { useEffect, useState } from 'react';
import DateRangePicker from '../../components/dateRangePicker/bankIcon.component';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import { getChannels } from '../../store/features/channel/channel.slice';
import { getCompanyIndicators, getCompanySubscription } from '../../store/features/company/company.slice';
import SubscriptionTable from './components/SubscriptionTable';
import { formatToCurrency } from '../../helpers/firebase.helpers';

export default function Indicators() {
	const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()]);
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const dispatch = useAppDispatch();
	const { loadingSubscription, subscription, loadingIndicators, indicators } = useAppSelector((state: RootState) => state.company);

	useEffect(() => {
		dispatch(getCompanySubscription());
		dispatch(getCompanyIndicators());
	}, []);

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			{/*<DateRangePicker
				selectedDates={selectedDates}
				onDateChange={setSelectedDates}
			/>*/}
			<SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 3 }} gap='20px' mb='20px'>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdPerson} color={brandColor} />}
						/>
					}
					name='Leads cadastrados'
					value={indicators?.leadsCount || 0}
					loading={loadingIndicators}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdPerson} color={'green'} />}
						/>
					}
					name='Novos leads com crédito'
					value={indicators?.leadsOpportunities || 0}
					loading={loadingIndicators}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdAttachMoney} color={'green'} />}
						/>
					}
					name='Oportunidade de venda'
					value={formatToCurrency(indicators?.sellOpportunities || 0)}
					loading={loadingIndicators}
				/>
				{/*<MiniStatistics growth='+23%' name='Sales' value='$574.34' />
				<MiniStatistics
					endContent={
						<Flex me='-16px' mt='10px'>
							<FormLabel htmlFor='balance'>
								<Avatar src={Usa} />
							</FormLabel>
							<Select id='balance' variant='mini' mt='5px' me='0px' defaultValue='usd'>
								<option value='usd'>USD</option>
								<option value='eur'>EUR</option>
								<option value='gba'>GBA</option>
							</Select>
						</Flex>
					}
					name='Your balance'
					value='$1,000'
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
							icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
						/>
					}
					name='New Tasks'
					value='154'
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />}
						/>
					}
					name='Total Projects'
					value='2935'
				/>*/}
			</SimpleGrid>
			<SubscriptionTable subscription={subscription} />
			{/*<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
				<SubscriptionTable subscription={subscription} />
				<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
					<Tasks />
					<MiniCalendar h='100%' minW='100%' selectRange={false} />
				</SimpleGrid>
			</SimpleGrid>
			<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
				<TotalSpent />
				<WeeklyRevenue />
			</SimpleGrid>
			<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
				<CheckTable tableData={tableDataCheck} />
				<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
					<DailyTraffic />
					<PieCard />
				</SimpleGrid>
			</SimpleGrid>*/}
		</Box>
	);
}
