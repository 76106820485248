import { HStack, Text } from '@chakra-ui/react';
import { BsInfoCircle } from 'react-icons/bs';
import React, { ReactNode } from 'react';

export interface AlertHelper {
  message?: string,
  component?: ReactNode,
  type?: 'info' | 'warning',
}

export default function AlertHelper({ message, component, type = 'info' }: AlertHelper) {
  return (
    <HStack background={type === 'info' ? 'aliceblue' : 'lemonchiffon'} spacing={3} p={2} borderRadius={5}>
      <BsInfoCircle size={18} color={type === 'info' ? 'blue' : 'goldenrod'} />
      {message ?
        <Text color='gray.700' fontSize={14}>
          {message}
        </Text> : null}
      {component ? component : null}
    </HStack>
  );
}
