import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CreateLeadDto } from '../../../models/lead.model';
import { MdPerson, MdPersonAdd } from 'react-icons/md';
import MiniStatistics from '../../../components/card/MiniStatistics';
import UploadFile from './UploadFile';
import IconBox from '../../../components/icons/IconBox';
import { isCPFValid, isPhoneValid } from '../../../helpers/lead.helpers';
import { InputSelect } from '../../../components/inputSelect/inputSelect.component';
import { BankType, BankTypeName } from '../../../models/robot.model';

export interface IModalCreateBatch {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  campaingId: string;
  onUpload: (leads: Array<CreateLeadDto>, bank?: BankType) => void;
}

export default function ModalCreateBatch({ isOpen, onUpload, onClose, campaingId }: IModalCreateBatch) {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [file, setFile] = useState<string>(null);
  const [data, setData] = useState<Array<any>>([]);
  const [fileLeads, setFileLeads] = useState<Array<CreateLeadDto>>([]);
  const [hasAnyCredit, setHasAnyCredit] = useState<boolean>(false);
  const [selectedBank, setSelectecBank] = useState<BankType>(null);
  useEffect(() => {
    if (data && data.length) {
      let hasAnyCreditControl = false;
      const newLeads: Array<CreateLeadDto> = [];
      data.forEach(l => {
        const lead = new CreateLeadDto();
        const cpf = isCPFValid(l[0] || '');
        const phoneNumber = isPhoneValid(l[2] || '');
        const name = String(l[1] || '').trim();
        const credit = l[3] ? parseFloat(l[3].trim().replace('.', '').replace(',', '.')) : 0;
        if (cpf) {
          lead.name = name;
          lead.phone = phoneNumber || '';
          lead.cpf = cpf;
          try {
            lead.credit = isNaN(credit) ? 0 : credit;
            if (lead.credit) {
              hasAnyCreditControl = true;
            }
          } catch (e) {
            lead.credit = 0;
          }
          if (selectedBank) {
            lead.bank = selectedBank;
          }
          newLeads.push(lead);
        }
      });
      setFileLeads(newLeads);
      setHasAnyCredit(hasAnyCreditControl);
    }
  }, [data]);

  function closeModal() {
    onUpload(fileLeads, selectedBank);
    setData([]);
    setFile(null);
    setFileLeads([]);
    setHasAnyCredit(false);
    onClose();
  }

  useEffect(() => {
    console.log('useEffect', selectedBank)
    if (selectedBank) {
      const leads = [...fileLeads];
      for (const lead of leads) {
        lead.bank = selectedBank;
      }
      setFileLeads(leads);
    }
  }, [selectedBank]);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={'2xl'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Importar base para campanha</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <UploadFile
              onFiles={(f) => setFile(f)}
              onData={(data) => setData(data)}
            />
            {data.length ? <HStack mb='20px'>
              <MiniStatistics
                startContent={
                  <IconBox
                    w='56px'
                    h='56px'
                    bg={'secondaryGray.300'}
                    icon={<Icon w='32px' h='32px' as={MdPerson} color={'brand.500'} />}
                  />
                }
                name='Linhas do arquivo'
                value={data.length}
              />
              <MiniStatistics
                startContent={
                  <IconBox
                    w='56px'
                    h='56px'
                    bg={'brand.500'}
                    icon={<Icon w='32px' h='32px' as={MdPersonAdd} color={'secondaryGray.300'} />}
                  />
                }
                name='Leads válidos'
                value={fileLeads.length}
              />
            </HStack> : null}
            {hasAnyCredit ? <Box mb={5}>
              <InputSelect
                key={'bank-select'}
                label='Banco'
                placeholder='Selecione'
                value={selectedBank}
                labelWidth={50}
                onChange={(value) => setSelectecBank(value as BankType)}
                error={hasAnyCredit && !selectedBank ? 'Há leads com créditos, selecione o banco' : ''}
                options={Object.values(BankType).map(v => {
                  return {
                    label: BankTypeName[v],
                    value: v,
                  };
                })}
              />
            </Box> : null}
            <Flex gap={5}>
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeModal} colorScheme='blue' size={'md'}
                  isDisabled={fileLeads.length === 0}>
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
