import { Checkbox, Heading, HStack, Tag, TagLabel, Text } from '@chakra-ui/react';
import * as React from 'react';
import { Channel, ChannelStatus } from '../../../models/channel.model';
import { formatPhoneNumber } from '../../../helpers/firebase.helpers';

interface ChannelCardProps {
  channel: Channel;
  onChange: (id: string) => void;
}

export default function ChannelCheck({channel, onChange}: ChannelCardProps) {
  return (
    <HStack justify={'space-between'}>
      <HStack align={'center'}>
        <Checkbox size="lg" colorScheme="blue" onChange={() => onChange(channel.id)} isDisabled={channel?.status !== ChannelStatus.ONLINE}>
          <Heading size="sm">{channel?.name}</Heading>
        </Checkbox>
        <Text>{formatPhoneNumber(channel?.phone)}</Text>
      </HStack>
      {channel?.status === ChannelStatus.ONLINE ? <Tag
        size={'md'}
        borderRadius="full"
        variant="solid"
        colorScheme="green"
      >
        <TagLabel>ATIVO</TagLabel>
      </Tag> : <Tag
        size={'md'}
        borderRadius="full"
        variant="solid"
        colorScheme="red"
      >
        <TagLabel>DESCONECTADO</TagLabel>
      </Tag>}
    </HStack>
  );
}
