import { Box, Flex, Icon, Progress, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import * as React from 'react';
import { MdCancel, MdCheckCircle, MdOutlineError } from 'react-icons/md';
import {
  CompanySubscription,
  CompanySubscriptionModuleType,
  CompanySubscriptionModuleTypeName,
} from '../../../models/company.model';
import { useEffect } from 'react';
import { getCompanySubscription } from '../../../store/features/company/company.slice';

type RowObj = {
  name: string;
  available: number;
  using: number;
  percent: number;
};

function createTable(subscription: CompanySubscription): Array<RowObj> {
  if (!subscription) {
    return [];
  }
  return [
    {
      name: CompanySubscriptionModuleTypeName[CompanySubscriptionModuleType.BALANCE_ROBOT],
      available: subscription.available.balanceRobot,
      using: subscription.using.balanceRobot,
      percent: subscription.using.balanceRobot / (subscription.available.balanceRobot || 1) * 100,
    },
    {
      name: CompanySubscriptionModuleTypeName[CompanySubscriptionModuleType.TYPING_ROBOT],
      available: subscription.available.typingRobot,
      using: subscription.using.typingRobot,
      percent: subscription.using.typingRobot / (subscription.available.typingRobot || 1) * 100,
    },
    {
      name: CompanySubscriptionModuleTypeName[CompanySubscriptionModuleType.CHANNEL],
      available: subscription.available.channel,
      using: subscription.using.channel,
      percent: subscription.using.channel / (subscription.available.channel || 1) * 100,
    },
    {
      name: CompanySubscriptionModuleTypeName[CompanySubscriptionModuleType.SMS],
      available: subscription.available.sms,
      using: subscription.using.sms,
      percent: subscription.using.sms / (subscription.available.sms || 1) * 100,
    },
  ];
}

const columnHelper = createColumnHelper<RowObj>();
export default function SubscriptionTable(props: { subscription: CompanySubscription }) {
  const { subscription } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  let defaultData = createTable(subscription);
  const [data, setData] = React.useState(() => [...defaultData]);
  useEffect(() => {
    setData(createTable(subscription));
  }, [subscription]);
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Módulo
        </Text>
      ),
      cell: (info: any) => (
        <Flex align='center'>
          <Text color={textColor} fontSize='sm' fontWeight='700'>
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('available', {
      id: 'available',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Contratado
        </Text>
      ),
      cell: (info) => (
        <Flex align='center'>
          <Text color={textColor} fontSize='sm' fontWeight='700'>
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('using', {
      id: 'using',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Em uso
        </Text>
      ),
      cell: (info) => (
        <Flex align='center'>
          <Text color={textColor} fontSize='sm' fontWeight='700'>
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('percent', {
      id: 'percent',
      header: () => (
        <>
          <Text
            justifyContent='space-between'
            align='center'
            fontSize={{ sm: '10px', lg: '12px' }}
            color='gray.400'>
          </Text>
        </>
      ),
      cell: (info) => (
        <Flex align='center'>
          <Progress variant='table' colorScheme='brandScheme' h='8px' w='108px' value={info.getValue()} />
        </Flex>
      ),
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });
  return (
    <Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px='25px' mb='8px' justifyContent='space-between' align='center'>
        <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
          Meu uso
        </Text>
        <Menu />
      </Flex>
      <Box>
        <Table variant='simple' color='gray.500' mb='24px' mt='12px'>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe='10px'
                      borderColor={borderColor}
                      cursor='pointer'
                      onClick={header.column.getToggleSortingHandler()}>
                      <Flex
                        justifyContent='space-between'
                        align='center'
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color='gray.400'>
                        {flexRender(header.column.columnDef.header, header.getContext())}{{
                        asc: '',
                        desc: '',
                      }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.slice(0, 5).map((row) => {
              return (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td
                        key={cell.id}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor='transparent'>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
