import { LeadProposalStatus } from './lead.model';

export enum ChannelStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE'
}

export enum ChannelType {
  WHATSAPP = 'WHATSAPP',
  SMS = 'SMS'
}

export const ChannelTypeName = {
  [ChannelType.WHATSAPP]: 'WhatsApp',
  [ChannelType.SMS]: 'SMS',
};

export interface ChannelFilter {
  type?: Array<ChannelType>;
  status?: Array<ChannelStatus>;
}

export class Channel {
  constructor() {
  }

  id: string;
  companyId: string;
  status: ChannelStatus;
  type: ChannelType;
  qr: string;
  name: string;
  phone: string | null;
}
