import {
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Input,
  Link,
  Select,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import {
  Lead,
  LeadMessageStatus,
  LeadProposalStatus,
  messageStatusName,
  proposalStatusName,
} from '../../../models/lead.model';
import React from 'react';
import { PhoneIcon } from '@chakra-ui/icons';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { removeLead, updateLead } from '../../../store/features/leads/leads.api';
import { RootState } from '../../../store/store';
import { showToast } from '../../../services/toast.service';
import { BiTrashAlt } from 'react-icons/bi';
import ConfirmationButton from '../../../components/buttons/RemoveConfirmation';
import InputMask from 'react-input-mask';

interface IModalLead {
  lead: Lead;
  isOpen: boolean;
  onClose: (updated: boolean) => void
}

export function ModalLead({lead, isOpen, onClose}: IModalLead) {
  const { loadingUpdate } = useAppSelector((state: RootState) => state.leads);
  const dispatch = useAppDispatch();
  const { handleSubmit, handleChange, values} = useFormik({
    initialValues: {
      phone: lead.phone,
      lastMessageStatus: lead.lastMessageStatus,
      lastProposalStatus: lead.lastProposalStatus,
    },
    onSubmit: async (values) => {
      const response = await dispatch(updateLead({
        id: lead.id,
        ...values,
      }));
      if (response.payload) {
        showToast('success', 'Lead editado!')
        onClose(true);
      } else {
        showToast('error', 'Falha!')
      }
    },
  });

  async function remove() {
    const response = await dispatch(removeLead(lead.id));
    if (response.payload) {
      showToast('success', 'Lead removido!')
      onClose(true);
    } else {
      showToast('error', 'Falha!')
    }
  }

  return (
    <Drawer
      size={'xl'}
      isOpen={isOpen}
      placement='right'
      onClose={() => onClose(false)}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Flex alignItems={'center'}>
            <Avatar
              size={'lg'}
              mr={5}
              name={lead?.name}
              src={lead?.photo}
            />
            <Text>{lead?.name}</Text>
          </Flex>
          </DrawerHeader>
        <DrawerBody>
          <Box borderWidth='1px' borderRadius='lg' p={5} overflow='hidden' mb={5}>
            <SimpleGrid columns={1} spacing={{ base: '20px', xl: '20px' }}>
              <HStack alignItems={'center'}>
                <Text as={'b'} textAlign={'right'} w={200}>Telefone:</Text>
                <Input
                  as={InputMask}
                  mask="(99) 99999-9999"
                  maskChar={null}
                  maxW={300}
                  placeholder='(xx) xxxxx-xxxx'
                  name='phone'
                  value={values.phone}
                  onChange={handleChange('phone')}
                />
              </HStack>
              <HStack alignItems={'center'}>
                <Text as={'b'} textAlign={'right'} w={200}>Última proposta:</Text>
                <Select maxW={300} placeholder="Última proposta" onChange={handleChange('lastProposalStatus')}
                        value={values?.lastProposalStatus}>
                  {Object.values(LeadProposalStatus).map(s =>
                    <option value={s}>{proposalStatusName[s]}</option>
                  )}
                </Select>
              </HStack>
              <HStack alignItems={'center'}>
                <Text as={'b'} textAlign={'right'} w={200}>Última mensagem:</Text>
                <Select maxW={300} placeholder="Última mensagem" onChange={handleChange('lastMessageStatus')}
                        value={values?.lastMessageStatus}>
                  {Object.values(LeadMessageStatus).map(s =>
                    <option value={s}>{messageStatusName[s]}</option>
                  )}
                </Select>
              </HStack>
              {/*<CanvasTextImage name={lead.name} value={123}/>*/}
             {/* <HStack alignItems={'center'}>
                <Text as={'b'} textAlign={'right'} w={200}>Última simulação:</Text>
                <Select maxW={300} placeholder="Última simulação" onChange={handleChange('lastSimulationStatus')}
                        value={values?.lastSimulationStatus}>
                  {Object.values(LeadSimulationStatus).map(s =>
                    <option value={s}>{simulationStatusName[s]}</option>
                  )}
                </Select>
              </HStack>
              {values?.lastSimulationStatus ? <HStack alignItems={'center'}>
                <Text as={'b'} textAlign={'right'} w={200}>Banco:</Text>
                <FormControl isInvalid={values?.lastSimulationStatus && !values?.bank} maxW={300}>
                  <Select

                    placeholder={'Selecione'}
                    onChange={handleChange('bank')}
                    value={values?.bank}
                  >
                    {Object.values(BankType).map(s =>
                      <option value={s}>{BankTypeName[s]}</option>,
                    )}
                  </Select>
                </FormControl>
              </HStack> : null}*/}
            </SimpleGrid>
          </Box>
          {/* {lead && lead.fgts && lead.fgts ? <Box borderWidth='1px' borderRadius='lg' p={5} overflow='hidden' mb={10}>
            <Text as={'b'} textAlign={'right'} w={200}>Última Simulação FGTS:</Text>
            <VStack>
              {lead && lead.fgts && lead.fgts.map(s =>
                <Card>
                  <Text fontSize='md' as='b'>{s.bank}</Text>
                  <Text mt={2} w='100px' fontSize='sm' as='b'>Situação:</Text> <Text fontSize='sm'>{s?.situation}</Text>
                  <Text mt={2} w='100px' fontSize='sm' as='b'>Valor:</Text> <Text
                  fontSize='sm'>{formatToCurrency(s?.credit)}</Text>
                  <Link as={"a"} fontSize='sm' color={'darkblue'} mt={2} href={s?.url} isExternal>
                    Simulação
                  </Link>
                </Card>
              )}
            </VStack>
          </Box> : null } */}
        </DrawerBody>
        <DrawerFooter>
          <Flex w={'100%'} justify={'space-between'}>
            <Box>
              <Link mr={5} href={`https://wa.me/${lead?.phone}`} isExternal>
                <IconButton
                  colorScheme='green'
                  aria-label='Search database'
                  size={'md'}
                  icon={<PhoneIcon />}
                />
              </Link>
              <ConfirmationButton onConfirm={remove}>
                <Button rightIcon={<BiTrashAlt />} colorScheme='red' size={'md'} isLoading={loadingUpdate}>
                  Remover
                </Button>
              </ConfirmationButton>
            </Box>
            <Box>
              <Button colorScheme='gray' mr={3} onClick={() => onClose(false)}>
                Fechar
              </Button>
              <Button isLoading={loadingUpdate} colorScheme='blue' mr={3} onClick={() => handleSubmit()}>
                Salvar
              </Button>
            </Box>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )


}
