import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import * as React from 'react';

export default function ConfirmationButton({onConfirm, children}: {onConfirm: () => void, children: ReactNode}) {
  const initialRef = React.useRef(null);
  const { isOpen, onToggle, onClose } = useDisclosure();

  function confirm() {
    onToggle();
    onConfirm();
  }

  return (
    <Popover
      initialFocusRef={initialRef}
      placement='bottom'
      closeOnBlur={true}
      returnFocusOnClose={true}
      onClose={onClose}
      closeOnEsc={true}
    >
      <PopoverTrigger>
        {children}
      </PopoverTrigger>
      <PopoverContent color='white' bg='darkred' borderColor='darkred'>
        <PopoverHeader pt={4} fontWeight='bold' border='0'>
         Confirma a operação?
        </PopoverHeader>
        <PopoverArrow bg='darkred' />
        <PopoverCloseButton />
        <PopoverBody>
          Essa operação não poderá ser disfeita.
        </PopoverBody>
        <PopoverFooter
          border='0'
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          pb={4}
        >
         <Spacer/>
          <ButtonGroup size='sm'>
            <Button colorScheme='red' onClick={confirm}>
              Confirmar
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}
