
export function isCPFValid(cpf: string): string | null {
  // Remover caracteres especiais e espaços em branco do CPF
  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.padStart(11, '0');

  // Verificar se o CPF possui 11 dígitos
  if (cpf.length !== 11) {
    return null;
  }

  // Verificar se todos os dígitos do CPF são iguais (CPF inválido)
  if (/^(\d)\1{10}$/.test(cpf)) {
    return null;
  }

  // Calcular o primeiro dígito verificador
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(cpf.charAt(9))) {
    return null;
  }

  // Calcular o segundo dígito verificador
  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(cpf.charAt(10))) {
    return null;
  }

  // Se chegou até aqui, o CPF é válido
  return cpf;
}

export function isPhoneValid(phone: string): string | null {
  phone = phone.replace(/\D/g, '');
  if(phone.length === 10){
    phone = phone.replace(/^(\d{2})(\d{8})$/, "$1" + "9" + "$2");
  }
  const test = /^(\d{11})$/.test(phone);
  if (test) {
    return phone;
  } else {
    return null;
  }
}
