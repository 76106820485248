import axios from 'axios';
import { logout } from './wesend.api';
import queryString from 'query-string';

// const API_URL = 'http://localhost:3030';
const API_URL = 'https://api.wesend.app';

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (401 === error?.response?.status && !error.request?.responseURL.includes('testToken') && !error.request?.responseURL?.includes('auth/login')) {
    logout();
    return Promise.reject(error);
  }
  if (error.response && error.response?.data && error.response.data?.message && Array.isArray(error.response.data.message)) {
    return Promise.reject(error.response.data.message.join(', '));
  } else {
    return Promise.reject(error)
  }
});

export default class WesendRequests {
  // token;

  constructor(token?: string) {
    // alert('criatoken')
    // this.token = `Bearer ${token || ''}`;
  }

  setToken(token: string) {
    console.log('setToken', token)
    // alert('settolen')
    // this.token = `Bearer ${token || ''}`;
  }

  get = async (path: string, params: any): Promise<any> => {
    try {
      console.log(`GET ${path}`)
      const query = queryString.stringify(params, { arrayFormat: 'bracket'});
      const result = await axios.get(`${API_URL}/${path}?${query}`);
      return result.data;
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.message) {
        throw e.response.data.message;
      }
      throw e;
    }
  };

  post = async (path: string, data: any): Promise<any> => {
    try {
      const result = await axios.post(`${API_URL}/${path}`, data);
      return result.data;
    } catch (e: any) {
      console.log(`POST ${path}`, e);
      if (e.response && e.response.data && e.response.data.message) {
        throw e.response.data.message;
      }
      throw e;
    }
  };

  put = async (path: string, data: any): Promise<any> => {
    try {
      const result = await axios.put(`${API_URL}/${path}`, data);
      return result.data;
    } catch (e: any) {
      console.log(`PUT ${path}`, e);
      // if (e.response && e.response.data && e.response.data.message) {
      //   throw e.response.data.message;
      // }
      throw e;
    }
  };

  patch = async (path: string, data: any): Promise<any> => {
    try {
      const result = await axios.patch(`${API_URL}/${path}`, data);
      return result.data;
    } catch (e: any) {
      console.log(e.response.data.message);
      if (e.response && e.response.data && e.response.data.message) {
        throw e.response.data.message;
      }
      throw e;
    }
  };

  delete = async (path: string): Promise<any> => {
    try {
      const result = await axios.delete(`${API_URL}/${path}`);
      return result.data;
    } catch (e: any) {
      console.log(e.response.data.message);
      if (e.response && e.response.data && e.response.data.message) {
        throw e.response.data.message;
      }
      throw e;
    }
  };
}
