import * as React from 'react';
import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import { BiTrashAlt } from 'react-icons/bi';
import ConfirmationButton from '../../../components/buttons/RemoveConfirmation';
import { InputNumber } from '../../../components/inputNumber/inputNumber.component';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/store';
import { removeMany } from '../../../store/features/leads/leads.api';
import { ChangeManyLeadsType } from '../../../models/lead.model';
import { LeadFilterInput } from '../../../services/api/lead/lead.dto.wesendapi';
import { showToast } from '../../../services/toast.service';

export interface IModalSelectedLeads {
  totalCount: number;
  selectedLeads: Array<string>;
  filter: LeadFilterInput;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onUpdate: () => void;
}

export default function ModalRemoveLeads({ selectedLeads, isOpen, onOpen, onClose, totalCount, filter, onUpdate }: IModalSelectedLeads) {

  const dispatch = useAppDispatch();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [selectionType, setSelectionType] = useState<ChangeManyLeadsType>('selected');
  const [count, setCount] = useState(selectedLeads.length);
  const { loadingUpdate } = useAppSelector((state: RootState) => state.leads);

  async function remove() {
    const result = await dispatch(removeMany({
      count,
      filter,
      ids: selectedLeads,
      type: selectionType,
    }));
    if (result.payload) {
      showToast('success', 'Leads removidos!');
      onClose();
      onUpdate();
    }
  }

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Remover leads</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <RadioGroup onChange={(v) => setSelectionType(v as ChangeManyLeadsType)} value={selectionType}>
              <Stack direction='column' gap={5}>
                <Radio value='selected'><b>{selectedLeads.length}</b> leads selecionados</Radio>
                {totalCount <= 1000 ?
                  <Radio value='all' disabled={totalCount > 1000}>Todos <b>({totalCount})</b></Radio> : null}
                <Radio value='count'>Definir quantidade</Radio>
              </Stack>
              {selectionType === 'count' ? <Box mt={5}>
                <FormControl>
                  <InputNumber min={1}
                               max={totalCount}
                               value={count}
                               onChange={(e) => setCount(e)} />
                </FormControl>
              </Box> : null}
            </RadioGroup>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancelar</Button>
          <ConfirmationButton onConfirm={remove}>
            <Button rightIcon={<BiTrashAlt />} colorScheme='red' size={'md'} isLoading={loadingUpdate}>
              Remover
            </Button>
          </ConfirmationButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
