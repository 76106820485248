import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { wesendApi } from '../../../services/api/wesend.api';
import axios from 'axios';

export interface AuthState {
  displayName?: string | null;
  email?: string | null;
  authenticated?: boolean;
  error: boolean;
  loading: boolean;
  waitingAuth: boolean;
  company: string;
  companyName: string;
}

const initialState: AuthState = {
  displayName: undefined,
  email: undefined,
  authenticated: undefined,
  error: false,
  loading: false,
  waitingAuth: true,
  company: undefined,
  companyName: undefined,
};

interface PayLoad {
  password: string;
  email: string;
}

export const login = createAsyncThunk(
  'login',
  async (req: any, {dispatch}) => {
    try {
      const authResponse = await wesendApi.login({
        email: req.email,
        password: req.password
      });
      axios.defaults.headers.common['Authorization'] = `Bearer ${authResponse.accessToken}`;
      // wesendApi.setToken(authResponse.accessToken);
      localStorage.setItem('token', authResponse.accessToken);
      localStorage.setItem('userName', authResponse.userName);
      localStorage.setItem('companyName', authResponse.companyName);
      dispatch(userSetAuth({ success: true, displayName: authResponse.userName, companyName: authResponse.companyName }));
    } catch (error) {
      dispatch(userSetAuth({ success: false, error: 'wrong autentication' }));
    }
  }
);

export const verifyAuth = createAsyncThunk(
  'auth/verify',
  async (_, {dispatch}) => {
    try {
      const authenticated = await wesendApi.testToken();
      if (authenticated) {
        const userName = localStorage.getItem('userName');
        const companyName = localStorage.getItem('companyName');
        dispatch(userSetAuth({ success: true, displayName: userName, companyName }));
      } else {
        dispatch(userSetAuth({ success: false }));
      }
    } catch (e) {
      dispatch(userSetAuth({ success: false }));
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userSetAuth: (state, action) => {
      state.authenticated = action.payload.success;
      state.displayName = action.payload.displayName;
      state.company = action.payload.company;
      state.companyName = action.payload.companyName;
      state.waitingAuth = false;
      state.error = action.payload.error;
    },
  },
  extraReducers: builder => {
    builder.addCase(login.pending, (state, action) => {
      // state.displayName = action.payload.displayName;
      // state.email = action.payload.email;
      // state.authenticated = true;
      state.error = false;
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      // state.displayName = action.payload.displayName;
      // state.email = action.payload.email;
      // state.authenticated = true;
      state.loading = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.error = true;
      state.loading = false;
    });
  },
});

export const { userSetAuth } = authSlice.actions;


