import { wesendRequests } from '../wesend.api';
import { PaginatedResult } from '../lead/lead.dto.wesendapi';
import { CreateLeadDto } from '../../../models/lead.model';
import { CreateVoiceCampaignDto, VoiceCampaign, VoiceCampaignFilterInput } from '../../../models/voiceCampaing.model';

export const getVoiceCampaigns = async (data: VoiceCampaignFilterInput & {
  // orderBy?: UserOrderByInput,
  page?: number,
}): Promise<PaginatedResult<VoiceCampaign>> => {
  try {
    const path = 'voiceCampaign';
    const result = await wesendRequests.get(path, data);
    return result;
  } catch (e) {
    throw e;
  }
};

export const createVoiceCampaign = async (campaign: CreateVoiceCampaignDto): Promise<string> => {
  try {
    const path = `voiceCampaign`;
    const result = await wesendRequests.post(path, campaign);
    return result;
  } catch (e) {
    console.log('createVoiceCampaign', e)
    throw e;
  }
};

export const stopVoiceCampaign = async (id: string): Promise<VoiceCampaign> => {
  try {
    const path = `voiceCampaign/${id}/stop`;
    const result = await wesendRequests.post(path, {});
    return result;
  } catch (e) {
    console.log('stopVoiceCampaign', e)
    throw e;
  }
};

export const createBatchVoiceCampaign = async (campaingId: string, leads: Array<CreateLeadDto>): Promise<string> => {
  try {
    const path = `voiceCampaign/importLeads/${campaingId}`;
    const result = await wesendRequests.post(path, leads);
    return result;
  } catch (e) {
    console.log('createTypingRobot', e)
    throw e;
  }
};

export const exportVoiceCampaignBatch = async (campaingId: string): Promise<string> => {
  try {
    const path = `voiceCampaign/exportBatch/${campaingId}`;
    const result = await wesendRequests.post(path, {});
    return result;
  } catch (e) {
    console.log('createTypingRobot', e)
    throw e;
  }
};
