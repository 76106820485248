import { wesendRequests } from '../wesend.api';
import { Robot, RobotFilterInput, RobotStatusType } from '../../../models/robot.model';
import { PaginatedResult } from './robot.dto.wesendapi';
import { CreateLeadDto } from '../../../models/lead.model';

export const getRobots = async (data: RobotFilterInput & {
  // orderBy?: UserOrderByInput,
  page?: number,
}): Promise<PaginatedResult<Robot>> => {
  try {
    const path = 'robot';
    const result = await wesendRequests.get(path, data);
    return result;
  } catch (e) {
    throw e;
  }
};

export const removeRobot = async (id: string): Promise<boolean> => {
  try {
    const path = `robot/${id}`;
    const result = await wesendRequests.delete(path);
    return result;
  } catch (e) {
    throw e;
  }
};

export const changeStatus = async (id: string, status: RobotStatusType): Promise<boolean> => {
  try {
    const path = `robot/changeStatus/${id}`;
    const result = await wesendRequests.post(path, { status });
    return result;
  } catch (e) {
    throw e;
  }
};

export const createRobot = async (robot: Robot): Promise<Robot> => {
  try {
    const path = `robot`;
    const result = await wesendRequests.post(path, robot);
    return result;
  } catch (e) {
    console.log('createRobot', e)
    throw e;
  }
};

export const editRobot = async (robot: Robot): Promise<Robot> => {
  try {
    const path = `robot/${robot.id}`;
    const result = await wesendRequests.put(path, robot);
    return result;
  } catch (e) {
    console.log('createRobot', e)
    throw e;
  }
};

export const createBatchRobot = async (typingRobotId: string, leads: Array<CreateLeadDto>): Promise<string> => {
  try {
    const path = `robot/importLeads/${typingRobotId}`;
    const result = await wesendRequests.post(path, leads);
    return result;
  } catch (e) {
    console.log('createBatchRobot', e)
    throw e;
  }
};

export const exportBatch = async (typingRobotId: string): Promise<string> => {
  try {
    const path = `robot/exportBatch/${typingRobotId}`;
    const result = await wesendRequests.post(path, {});
    return result;
  } catch (e) {
    console.log('createTypingRobot', e)
    throw e;
  }
};
