import {createStandaloneToast} from '@chakra-ui/react';
import {AlertStatus} from '@chakra-ui/alert';

export const showToast = (type: AlertStatus, description: string, title?: string): void => {
  const { toast } = createStandaloneToast();
  toast({
    title,
    description,
    status: type,
    duration: 10000,
    isClosable: true,
    position: 'top',
  });
};
