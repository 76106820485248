import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CreateLeadDto } from '../../../models/lead.model';
import { MdPerson, MdPersonAdd } from 'react-icons/md';
import MiniStatistics from '../../../components/card/MiniStatistics';
import UploadFile from './UploadFile';
import IconBox from '../../../components/icons/IconBox';
import { isCPFValid, isPhoneValid } from '../../../helpers/lead.helpers';

export interface IModalCreateBatch {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onUpload: (leads: Array<CreateLeadDto>) => void;
}

export default function ModalCreateBatch({ isOpen, onUpload, onClose }: IModalCreateBatch) {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [file, setFile] = useState<string>(null);
  const [data, setData] = useState<Array<any>>([]);
  const [fileLeads, setFileLeads] = useState<Array<CreateLeadDto>>([]);
  useEffect(() => {
    if (data && data.length) {
      const newLeads: Array<CreateLeadDto> = [];
      data.forEach(l => {
        const lead = new CreateLeadDto();
        const cpf = isCPFValid(l[0] || '');
        const phoneNumber = isPhoneValid(l[2] || '');
        const name = String(l[1] || '').trim();
        if (cpf) {
          lead.name = name;
          lead.phone = phoneNumber || '';
          lead.cpf = cpf;
          newLeads.push(lead);
        }
      });
      setFileLeads(newLeads);
    }
  }, [data]);

  function closeModal() {
    onUpload(fileLeads);
    setData([]);
    setFile(null);
    setFileLeads([]);
    onClose();
  }

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={'2xl'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Importar base para campanha</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <UploadFile
              onFiles={(f) => setFile(f)}
              onData={(data) => setData(data)}
            />
            {data.length ? <HStack mb='20px'>
              <MiniStatistics
                startContent={
                  <IconBox
                    w='56px'
                    h='56px'
                    bg={'secondaryGray.300'}
                    icon={<Icon w='32px' h='32px' as={MdPerson} color={'brand.500'} />}
                  />
                }
                name='Linhas do arquivo'
                value={data.length}
              />
              <MiniStatistics
                startContent={
                  <IconBox
                    w='56px'
                    h='56px'
                    bg={'brand.500'}
                    icon={<Icon w='32px' h='32px' as={MdPersonAdd} color={'secondaryGray.300'} />}
                  />
                }
                name='Leads válidos'
                value={fileLeads.length}
              />
            </HStack> : null}
            <Flex gap={5}>
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeModal} colorScheme='blue' size={'md'}
                  isDisabled={fileLeads.length === 0}>
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
