import {
  Box,
  Button,
  CircularProgress,
  Flex,
  Link,
  Progress,
  SimpleGrid,
  Table,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Card from 'components/card/Card';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { statusColor } from '../../../models/lead.model';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { HSeparator } from '../../../components/separator/Separator';
import { PaginatedMetaData } from '../../../services/api/lead/lead.dto.wesendapi';
import { RobotStatusType, RobotStatusTypeName } from '../../../models/robot.model';
import { TypingRobot } from '../../../models/typingRobot.model';

const columnHelper = createColumnHelper<TypingRobot>();

export default function TypingRobotsTable(props: {
  tableData: Array<TypingRobot>,
  onSelect?: (robot: TypingRobot) => void,
  onPageChange: (page: number) => void,
  pagination: PaginatedMetaData,
  loading: boolean,
}) {

  const {tableData, onSelect, onPageChange, pagination, loading} = props;
  const page = pagination.currentPage;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  let defaultData = tableData || [];
  const itemsPerPage = pagination.perPage;
  const pages = Math.ceil(pagination.total / itemsPerPage);

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
          NOME
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Link onClick={() => onSelect(info.row.original as TypingRobot)}>
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue()}
            </Text>
          </Link>
        </Flex>
      ),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
          Status
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Tag
            size={'sm'}
            borderRadius="full"
            variant="solid"
            colorScheme={statusColor[info.getValue() as RobotStatusType]}
          >
            <TagLabel>{RobotStatusTypeName[info.getValue() as RobotStatusType]}</TagLabel>
          </Tag>
        </Flex>
      ),
    }),
    columnHelper.accessor('type', {
      id: 'type',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
          Banco
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('login', {
      id: 'login',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
          Login
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
            <Text color={textColor} fontSize="sm">
              {info.getValue()}
            </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('simulationsLength', {
      id: 'simulationsLength',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='blue.400'>
          Progresso
        </Text>
      ),
      cell: (info) => (
        <SimpleGrid>
          <Flex align='center'>
            <Text me='10px' color={textColor} fontSize='sm' fontWeight='700'>
              {info.row.original.simulationsLength ? Math.floor((info.row.original.simulationsLength/info.row.original.batchLength) * 100) : 0}%
            </Text>
            <Text me='10px' color={textColor} fontSize='sm' mr={3}>
              {info.row.original.simulationsLength}/{info.row.original.batchLength}
            </Text>
          </Flex>
          <Progress variant='table' colorScheme='blue' h='8px' w='120px' value={info.row.original.simulationsLength ? Math.floor((info.row.original.simulationsLength/info.row.original.batchLength) * 100) : 0} />
        </SimpleGrid>
      )
    }),
    columnHelper.accessor('simulationsSuccessLength', {
      id: 'simulationsLength',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='green.400'>
          Sucesso
        </Text>
      ),
      cell: (info) => (
        <SimpleGrid>
          <Flex align='center'>
            <Text me='10px' color={textColor} fontSize='sm' fontWeight='700'>
              {info.row.original.simulationsSuccessLength ? Math.floor((info.row.original.simulationsSuccessLength/info.row.original.simulationsLength) * 100) : 0}%
            </Text>
            <Text me='10px' color={textColor} fontSize='sm' mr={3}>
              {info.row.original.simulationsSuccessLength}/{info.row.original.simulationsLength}
            </Text>
          </Flex>
          <Progress variant='table' colorScheme='green' h='8px' w='120px' value={info.row.original.simulationsSuccessLength ? Math.floor((info.row.original.simulationsSuccessLength/info.row.original.simulationsLength) * 100) : 0} />
        </SimpleGrid>
      )
    }),
    columnHelper.accessor('simulationsFailLength', {
      id: 'simulationsLength',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='red.400'>
          Falha
        </Text>
      ),
      cell: (info) => (
        <SimpleGrid>
          <Flex align='center'>
            <Text me='10px' color={textColor} fontSize='sm' fontWeight='700'>
              {info.row.original.simulationsFailLength ? Math.floor((info.row.original.simulationsFailLength/info.row.original.simulationsLength) * 100) : 0}%
            </Text>
            <Text me='10px' color={textColor} fontSize='sm' mr={3}>
              {info.row.original.simulationsFailLength}/{info.row.original.simulationsLength}
            </Text>
          </Flex>
          <Progress variant='table' colorScheme='red' h='8px' w='120px' value={info.row.original.simulationsFailLength ? Math.floor((info.row.original.simulationsFailLength/info.row.original.simulationsLength) * 100) : 0} />
        </SimpleGrid>
      )
    }),
  ];

  const [data, setData] = useState([...defaultData]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
  });
  return (
    <Card flexDirection="column" w="100%" px="0px" overflowX={{sm: 'scroll', lg: 'hidden'}}>
      {/*	<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					Complex Table
				</Text>
				<Menu />
			</Flex>*/}
      {/*<Box>*/}
      <Table variant="striped" color="gray.500" mb="24px" size={'sm'}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    pe="10px"
                    borderColor={borderColor}
                    cursor="pointer"
                    onClick={header.column.getToggleSortingHandler()}>
                    <Flex
                      justifyContent="space-between"
                      align="center"
                      fontSize={{sm: '10px', lg: '12px'}}
                      color="gray.400">
                      {flexRender(header.column.columnDef.header, header.getContext())}{{
                      asc: '',
                      desc: '',
                    }[header.column.getIsSorted() as string] ?? null}
                    </Flex>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Td
                      key={cell.id}
                      fontSize={{sm: '14px'}}
                      minW={{sm: '150px', md: '200px', lg: 'auto'}}
                      borderColor="transparent">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <HSeparator/>
      <Flex justifyContent={'space-between'} pr={10} pl={5} mt={5}>
        <Box>
          <Text>
            {pagination.next ? <span>{(page - 1) * pagination.perPage} a {(page) * pagination.perPage} de </span> : null}
            {pagination.total > 0 ? <b>{pagination.total}</b> : 'Nenhum'}
          </Text>
        </Box>
        <Box>
          {!!loading && <CircularProgress color={'gray'} size={5} isIndeterminate/>}
          {page > 1 ?
            <Button
              onClick={() => onPageChange(page - 1)}
              leftIcon={<ArrowBackIcon/>}
              colorScheme="gray"
              variant="ghost">
              Anterior
            </Button> : null}
          {page < pages && pages > 1 ?
            <Button
              onClick={() => onPageChange(page + 1)}
              rightIcon={<ArrowForwardIcon/>}
              colorScheme="gray"
              variant="ghost">
              Próxima
            </Button> : null}
        </Box>
      </Flex>
    </Card>
  );
}
