import * as React from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import FabComponent, { IFabActions } from '../../../components/fab/fab.component';
import { theme, ThemeProvider, useDisclosure } from '@chakra-ui/react';
import ModalRemoveLeads from './ModalRemoveLeads';
import { LeadFilterInput } from '../../../services/api/lead/lead.dto.wesendapi';
import ModalEditLeads from './ModalEditLeads';
import { RiEditLine } from 'react-icons/ri';

export interface IFabLeadsProperties {
  selectedLeads: Array<string>;
  totalCount: number;
  filter: LeadFilterInput;
  onUpdate: () => void;
}

export default function FabLeads({ selectedLeads, totalCount, filter, onUpdate}: IFabLeadsProperties) {

  const { isOpen: isOpenModalRemove, onOpen: onOpenModalRemove, onClose: onCloseModalRemove } = useDisclosure();
  const { isOpen: isOpenModalEdit, onOpen: onOpenModalEdit, onClose: onCloseModalEdit } = useDisclosure();

  const fabActions: Array<IFabActions> = [{
    label: 'Remover',
    icon: <DeleteIcon />,
    onClick: () => {
      onOpenModalRemove();
    },
    style: {
      backgroundColor: 'red',
    },
  },{
    label: 'Editar',
    icon: <RiEditLine />,
    onClick: () => {
      onOpenModalEdit();
    },
    style: {
      backgroundColor: 'darkgreen',
    },
  }];

  return (
    <>
      <FabComponent
        onOpen={() => {
        }}
        actions={fabActions} />
      <ModalRemoveLeads
        isOpen={isOpenModalRemove}
        onOpen={onOpenModalRemove}
        onClose={onCloseModalRemove}
        selectedLeads={selectedLeads}
        totalCount={totalCount}
        filter={filter}
        onUpdate={onUpdate}
      />
      <ModalEditLeads
        isOpen={isOpenModalEdit}
        onOpen={onOpenModalEdit}
        onClose={onCloseModalEdit}
        selectedLeads={selectedLeads}
        totalCount={totalCount}
        filter={filter}
        onUpdate={onUpdate}
      />
    </>
  );
}
