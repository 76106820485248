import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Channel, ChannelFilter, ChannelStatus } from '../../../models/channel.model';
import { wesendApi } from '../../../services/api/wesend.api';
import { PaginatedMetaData } from '../../../services/api/lead/lead.dto.wesendapi';
import { getCompanySubscription } from '../company/company.slice';

export interface LeadsState {
  channels: Channel[];
  qr: string;
  loading: boolean;
  loadingUpdate: boolean;
  error: string | null;
  status: ChannelStatus;
  filters: ChannelFilter | undefined;
  pagination: PaginatedMetaData | undefined;
}

const initialState: LeadsState = {
  channels: [],
  qr: '',
  loading: false,
  loadingUpdate: false,
  error: null,
  status: ChannelStatus.OFFLINE,
  filters: undefined,
  pagination: {
    total: 0,
    lastPage: 0,
    currentPage: 0,
    perPage: 20,
    prev: 0,
    next: 0,
  },
};

export const getChannels = createAsyncThunk<{ channels: Array<Channel>, pagination: PaginatedMetaData },{ page: number, filter: ChannelFilter}>(
  'channel/list',
  async (params) => {
    const channels = await wesendApi.channel.list({
      ...params.filter,
      page: params.page,
    });
    return { channels: channels.data, pagination: channels.meta };
  });

export const getChannel = createAsyncThunk<Channel, string>(
  'channel/get',
  async (id) => {
    return await wesendApi.channel.get(id);
  });

export const removeChannel = createAsyncThunk<{ success: boolean },{ id: string }>(
  'channel/remove',
  async (params, {dispatch, getState}) => {
    await wesendApi.channel.delete(params.id);
    dispatch(getCompanySubscription());
    return { success: true };
  },
);

export const restartChannel = createAsyncThunk<{ success: boolean },{ id: string }>(
  'channel/restart',
  async (params, {dispatch, getState}) => {
    await wesendApi.channel.restart(params.id);
    return { success: true };
  },
);

export const createChannel = createAsyncThunk<Channel, Channel>(
  'channel/create',
  async (channel, {dispatch, getState}) => {
    dispatch(getCompanySubscription());
    return await wesendApi.channel.create(channel);
  },
);

export const editChannel = createAsyncThunk<Channel, Channel>(
  'channel/edit',
  async (channel, {dispatch, getState}) => {
    return await wesendApi.channel.edit(channel);
  },
);

export const channelSlice = createSlice({
  name: 'Channel',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChannel.pending, (state) => {
        state.error = undefined;
      })
      .addCase(getChannel.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(getChannel.fulfilled, (state, action) => {
        state.error = null;
      })
      .addCase(getChannels.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(getChannels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getChannels.fulfilled, (state, action) => {
        state.error = null;
        state.channels = action.payload.channels;
        state.loading = false;
        state.pagination = action.payload.pagination;
      })
      .addCase(editChannel.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(editChannel.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(editChannel.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(removeChannel.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(removeChannel.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(removeChannel.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(createChannel.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(createChannel.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(createChannel.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(restartChannel.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(restartChannel.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(restartChannel.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
  },
});
