import { useEffect, useState } from 'react';
import {
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper, Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { InputRangeNumberType } from './filter.constants';

export function FilterItemInputRangeNumber(
  {
    onChange,
    value,
  }: {
    label: string,
    onChange: (v: InputRangeNumberType) => void,
    value: InputRangeNumberType;
  },
) {

  const [min, setMin] = useState<number>(1);
  const [max, setMax] = useState<number>(1000000);

  useEffect(() => {
    onChange({ min, max });
  }, [min, max]);

  return (
    <Flex align={'center'} gap={5}>
      <NumberInput
        size={'sm'}
        maxW={150}
        min={1}
        onChange={(v) => setMin(parseInt(v) || 0)}
        value={value.min}
        max={value.max}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Text>
        até
      </Text>
      <NumberInput
        size={'sm'}
        maxW={150}
        min={value.min}
        onChange={(v) => setMax(parseInt(v) || 0)}
        value={value.max}
        max={1000000}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Flex>
  );
}
