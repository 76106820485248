import { FormControl } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import * as React from 'react';
import { OptionItemType } from './filter.constants';

export function FilterItemInputOptions(
  {
    options,
    onChange,
    value,
    multi,
  }: {
    label: string,
    options: Array<OptionItemType>
    onChange?: (v: Array<OptionItemType>) => void,
    value?: Array<OptionItemType>;
    multi: boolean;
  },
) {

  function change(values: any) {
    onChange(values);
  }

  return (
    <FormControl>
      <Select
        size={'sm'}
        isMulti={multi}
        options={options}
        placeholder='Selecione ...'
        closeMenuOnSelect={true}
        onChange={change}
        noOptionsMessage={f => 'Todos os filtros já foram selecionados'}
        value={value}
      />
    </FormControl>
  );
}
